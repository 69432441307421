import { OpportunityInfo, Project } from '@/types';
import Axios, { AxiosStatic } from 'axios';

class ProjectService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async getOpportunityInfo(salesForceId: string): Promise<OpportunityInfo> {
    const response = await this.http.get(`/opportunityinfo/${salesForceId}`);
    return response.data;
  }

  async getProject(opportunityId: string): Promise<Project> {
    const response = await this.http.get(`/project/${opportunityId}`);
    return response.data;
  }
}

const projectService = new ProjectService(Axios);

export { projectService };
