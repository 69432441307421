import { CrudResponse } from '@/types/response/crudResponse';
import Axios, { AxiosStatic } from 'axios';

class ImportService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async importFromCpq(projectId: string, versionId: string, cpqSolutionId: string): Promise<CrudResponse | undefined> {
    const url: string = `import/cpqimport/${projectId}/${versionId}/${cpqSolutionId}`;
    const response = await Axios.get(url);
    return response.data;
  }

  async uploadStandardExcel(payload: any, createProject: boolean): Promise<CrudResponse | undefined> {
    if (!payload.importData || payload.importData.length == 0) {
      return;
    }

    const url: string = `import/standardimport/`;
    return createProject
      ? await this.sendImportCreateProjectRequest(payload, url)
      : await this.sendImportUpdateProjectRequest(payload, url);
  }

  async uploadSizingProject(payload: any, createProject: boolean): Promise<CrudResponse | undefined> {
    if (!payload.importData || payload.importData.length == 0) {
      return;
    }

    const url: string = `import/sizingprojectimport/`;
    return createProject
      ? await this.sendImportCreateProjectRequest(payload, url)
      : await this.sendImportUpdateProjectRequest(payload, url);
  }

  private async sendImportCreateProjectRequest(payload: any, url: string) {
    const formData = new FormData();
    formData.append('document', payload.importData);
    formData.append('projectName', payload.projectName);

    const response: any = await this.http.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }

  private async sendImportUpdateProjectRequest(payload: any, url: string) {
    const formData = new FormData();
    formData.append('document', payload.importData);
    formData.append('projectId', payload.projectId);
    formData.append('versionId', payload.versionId);
    formData.append('updateExistingData', payload.updateExistingData);

    const response: any = await this.http.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
}

const importService = new ImportService(Axios);

export { importService };
