import Axios, { AxiosStatic } from 'axios';
import { isEmpty } from 'lodash';
import { Comments } from '@/types';
import { CrudResponse } from '@/types/response/crudResponse';

class SizingService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async create(
    projectId: string,
    versionId: string,
    tagId: string,
    name: string,
    comments: Comments,
    process: any,
    type?: string
  ): Promise<CrudResponse> {
    if (!tagId) {
      throw new Error('Missing tagId');
    }

    const request: any = {
      projectId: projectId,
      versionId: versionId,
      sizing: {
        tagId: tagId,
        sizingName: name,
        sizingComment: comments.externalComment,
        internalComment: comments.internalComment,
        process: process
      }
    };

    if (type) {
      request.sizing.valveFunction = type;
    }

    const response = await this.http.post('/sizing', request);
    return response.data;
  }

  async read(projectId: string, versionId: string, opportunityId: string, sizingId: string): Promise<any> {
    if (!sizingId) {
      throw new Error('Missing sizingId');
    }

    const url =
      !isEmpty(projectId) && !isEmpty(versionId)
        ? `/sizing/${projectId}/${versionId}/${sizingId}`
        : `/sizing/${opportunityId}/${sizingId}`;
    const response = await this.http.get(url);
    return response.data;
  }

  async update(
    projectId: string,
    versionId: string,
    sizingId: string,
    deviceId: string,
    tagId: string,
    name: string,
    comments: Comments,
    process: any,
    device: any
  ): Promise<CrudResponse> {
    if (!sizingId) {
      throw new Error('Missing sizingId');
    }

    if (!tagId) {
      throw new Error('Missing tagId');
    }

    const response = await this.http.put('/sizing', {
      projectId: projectId,
      versionId: versionId,
      sizing: {
        tagId: tagId,
        sizingName: name,
        deviceId: deviceId,
        sizingComment: comments.externalComment,
        internalComment: comments.internalComment,
        process: process,
        sizingId: sizingId,
        device: device.device,
        result: device.result,
        actuatorResult: device.actuatorResult
      }
    });
    return response.data;
  }

  async delete(projectId: string, versionId: string, sizingId: string): Promise<CrudResponse> {
    if (!projectId) {
      throw new Error('Missing projectId');
    }

    if (!versionId) {
      throw new Error('Missing versionId');
    }

    if (!sizingId) {
      throw new Error('Missing sizingId');
    }

    const response = await this.http.delete(`/sizing/${projectId}/${versionId}/${sizingId}`);
    return response.data;
  }

  async copy(projectId: string, versionId: string, tagId: string, sizingId: string): Promise<CrudResponse> {
    if (!sizingId) {
      throw new Error('Missing sizingId');
    }

    if (!tagId) {
      throw new Error('Missing tagId');
    }

    const response = await this.http.post(`/sizing/${projectId}/${versionId}/${tagId}/${sizingId}/copy`);
    return response.data;
  }
}

const sizingService = new SizingService(Axios);

export { sizingService };
