import { Ref, ref } from 'vue';
import { IAuthenticationService } from './IAuthenticationService';
import { Store } from 'vuex';

class SalesforceAuthenticationService implements IAuthenticationService {
  private readonly nelprofSessionkey: string = 'nelprofUISfSession';
  private store: Store<any> | undefined;
  private fetchingRefreshToken: boolean = false;
  private isAuthenticatedRef: Ref<boolean> = ref(false);
  private loginRedirect: string | undefined = undefined;

  public isSalesforceAuthentication: boolean = true;

  constructor() {
    this.startSession();

    this.isAuthenticatedRef.value = this.sessionHas('accessToken');
  }

  get userName(): string {
    if (this.isAuthenticated.value) {
      return this.sessionGet('userName') ?? '';
    } else {
      return '';
    }
  }

  get nelesContact(): string {
    if (this.isValmetUser) {
      return this.sessionGet('userName') ?? '';
    } else {
      return '';
    }
  }

  get isValmetUser(): boolean {
    if (this.sessionHas('isValmetUser')) {
      return this.sessionGet('isValmetUser') === 'true';
    }

    return false;
  }

  get isFetchingRefreshToken(): boolean {
    return this.fetchingRefreshToken;
  }

  setup(store: Store<any>) {
    this.store = store;

    if (typeof localStorage !== 'undefined') {
      const requestedUrl = localStorage.getItem('requestedUrl');

      if (
        requestedUrl !== null &&
        window.location.href.indexOf('#') !== -1 &&
        requestedUrl !== window.location.pathname
      ) {
        //pop-up has been blocked
        if (window.location.href.indexOf('#id_token=') !== -1) {
          window.location.href = requestedUrl;
        } else {
          localStorage.removeItem('requestedUrl');
          window.location.href = requestedUrl;
        }
      }
    }
  }

  async getToken(): Promise<String> {
    if (!this.isAuthenticated) {
      throw new Error('Token retrieve failed');
    }

    this.startSession();

    if (this.sessionHas('accessToken') && this.sessionHas('accessTokenExpiresOn')) {
      const expireOn = new Date(this.sessionGet('accessTokenExpiresOn') ?? '');
      const currentDate = new Date();

      if (currentDate < expireOn) {
        this.isAuthenticatedRef.value = true;
        return this.sessionGet('accessToken') ?? '';
      } else {
        const response = await this.refreshAccessToken();
        this.removeSessionInfo();

        if (response !== undefined) {
          this.AddSessionInfo(response);
          this.fetchingRefreshToken = false;
          return response.accessToken ?? '';
        } else {
          this.fetchingRefreshToken = false;
          return await this.getAccessToken();
        }
      }
    } else {
      return await this.getAccessToken();
    }
  }

  get isAuthenticated(): Ref<boolean> {
    return this.isAuthenticatedRef;
  }

  login(loginRedirect: string | undefined): void {
    this.loginRedirect = loginRedirect;
    this.getAccessToken().then(() => {});
  }

  private async getAccessToken(): Promise<string> {
    const baseUrl = this.store?.state?.config?.baseUrl;
    const requestedUrl = this.loginRedirect ?? window.location.pathname;
    const url = `${baseUrl}authentication/login?redirectUri=${requestedUrl}`;
    const request = this.createRequest(url);
    const redirectJson = await this.sendLoginRequest(request);
    window.location.href = redirectJson.redirectUri;
    return Promise.resolve('');
  }

  private AddSessionInfo(response: IAuthenticationResponse) {
    this.isAuthenticatedRef.value = true;
    this.sessionSet('accessToken', response.accessToken);
    this.sessionSet('accessTokenExpiresOn', response.accessTokenExpiresOn);
    this.sessionSet('refreshToken', response.refreshToken);
    this.sessionSet('userName', response.userName);
    this.sessionSet('isValmetUser', response.isValmetUser);
  }

  private createRequest(url: string, method: string = 'GET'): Request {
    const headers: Headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    return new Request(url, {
      method: method,
      headers: headers
    });
  }

  async sendLoginRequest(request: Request): Promise<any> {
    const response = await fetch(request);
    const redirectJson = await response.json();
    return redirectJson;
  }

  async sendRequest(request: Request): Promise<IAuthenticationResponse | undefined> {
    const response = await fetch(request);

    if (response.status === 200) {
      return response.json();
    }

    return undefined;
  }

  logout(): void {
    if (this.sessionHas('accessToken') && this.sessionHas('accessTokenExpiresOn')) {
      const refreshToken = this.sessionGet('refreshToken');
      const baseUrl = this.store?.state?.config?.baseUrl;
      const url = `${baseUrl}authentication/logout?accessToken=${refreshToken}`;
      const request = this.createRequest(url);

      (async () => {
        await fetch(request);
        this.removeSessionInfo();
        window.location.href = '/';
      })();
    }
  }

  private removeSessionInfo() {
    this.isAuthenticatedRef.value = false;
    this.sessionRemove('accessToken');
    this.sessionRemove('accessTokenExpiresOn');
    this.sessionRemove('refreshToken');
    this.sessionRemove('userName');
  }

  public setSession(
    accessToken?: string,
    accessTokenExpiresOn?: string,
    refreshToken?: string,
    userName?: string,
    isValmetUser?: boolean
  ) {
    this.AddSessionInfo({ accessToken, accessTokenExpiresOn, refreshToken, userName, isValmetUser });
  }

  async refreshAccessToken(): Promise<IAuthenticationResponse | undefined> {
    this.fetchingRefreshToken = true;
    const baseUrl = this.store?.state?.config?.baseUrl;
    const refreshToken = this.sessionGet('refreshToken');
    const accessToken = this.sessionGet('accessToken');
    const url = `${baseUrl}authentication/refresh?refreshtoken=${refreshToken}&expiredAccessToken=${accessToken}`;
    const request = this.createRequest(url);
    const response = await this.sendRequest(request);
    return response;
  }

  private sessionHas(key: string): boolean {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session) {
      return false;
    }

    return key in session;
  }

  private sessionGet(key: string): string | null {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session || !(key in session)) {
      return null;
    }

    return session[key];
  }

  private sessionRemove(key: string): void {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session || !(key in session)) {
      return;
    }

    delete session[key];
    localStorage.setItem(this.nelprofSessionkey, JSON.stringify(session));
  }

  private sessionSet(key: string, value: any): void {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session) {
      return;
    }

    session[key] = value;
    localStorage.setItem(this.nelprofSessionkey, JSON.stringify(session));
  }

  private startSession(): void {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session) {
      localStorage.setItem(this.nelprofSessionkey, '{}');
    }
  }

  private parse(json: string | null): any {
    let data: any;

    if (!json) {
      return null;
    }

    try {
      data = JSON.parse(json);
    } catch (e) {
      return null;
    }

    return data;
  }
}

interface IAuthenticationResponse {
  accessToken: string | undefined;
  accessTokenExpiresOn: string | undefined;
  refreshToken: string | undefined;
  userName: string | undefined;
  isValmetUser: boolean | undefined;
}

export default SalesforceAuthenticationService;
