<template>
  <div id="not-found" class="row wrap-not-found">
    <div class="col">
      <p><span>Nothing here</span> <a href="/">Take me home</a></p>
    </div>
  </div>
</template>

<style lang="scss">
.wrap-not-found {
  background: url(../../public/images/404-3.png) right center no-repeat;
  background-size: cover;
  text-align: center;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  top: 45px;
  right: calc($spacer/2);
  bottom: 0;

  p {
    position: absolute;
    top: 50vh;
    left: 12vw;

    > span {
      font-size: 2.5rem;
      display: block;
      margin-bottom: 1rem;
      color: $gray-700;
    }
  }
}
</style>
