import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
  CreateRevisionRequest,
  RevisionHistoryItem,
  OpportunityInfo,
  ProjectState,
  RootState,
  UpdateRevisionRequest,
  Version,
  Project
} from '@/types';
import { CrudResponse } from '@/types/response/crudResponse';
import { importService, projectService, revisionService, versionService } from '@/services';
import { cloneDeep } from 'lodash';

export const initialState = (): ProjectState => {
  return {
    name: '',
    opportunityInfo: undefined,
    projectId: '',
    refreshVersion: 0,
    refreshVersionHistory: 0,
    revisions: undefined,
    selectedVersion: undefined,
    sfOpportunityId: undefined
  };
};

export const state: ProjectState = initialState();

export const getters: GetterTree<ProjectState, RootState> = {
  isTheNewestVersionOfTheRevisionOpened(state): boolean {
    if (state.revisions !== undefined && state.revisions.length > 0) {
      for (let index = 0; index < state.revisions.length; index++) {
        if (state.selectedVersion?.id === state.revisions[index].versions[0].id) {
          return true;
        }
      }
    }

    return false;
  }
};

export const mutations: MutationTree<ProjectState> = {
  INCREASE_REFRESH_VERSION(state: ProjectState) {
    state.refreshVersion++;
  },
  INITIALIZE_STATE: (state: ProjectState) => {
    Object.assign(state, initialState());
  },
  UPDATE_SELECTED_OPPORTUNITY_OPPORTUNITYINFO(state: ProjectState, value: OpportunityInfo) {
    state.opportunityInfo = value;
  },
  SET_PROJECT: (state: ProjectState, project: Project) => {
    state.name = project.name;
    state.opportunityInfo = project.opportunityInfo;
    state.projectId = project.id;
    state.sfOpportunityId = project.sfOpportunityId;
  },
  SET_REVISIONS(state: ProjectState, value: RevisionHistoryItem[]) {
    const revisions = cloneDeep(value);

    if (revisions !== undefined && revisions.length > 0) {
      revisions.forEach((x) => (x.opened = false));

      if (state.selectedVersion === undefined) {
        state.selectedVersion = revisions[0].versions[0];
        revisions[0].opened = true;
      } else {
        const index = revisions.findIndex((x) => x.id === state.selectedVersion?.revisionId);

        if (index === -1) {
          state.selectedVersion = revisions[0].versions[0];
          revisions[0].opened = true;
        } else {
          revisions[index].opened = true;
        }
      }
    }

    state.revisions = revisions;
    state.refreshVersionHistory++;
  },
  SET_SELECTED_VERSION(state: ProjectState, value: Version) {
    const revisions = cloneDeep(state.revisions);

    if (revisions !== undefined && revisions.length > 0) {
      revisions.forEach((x) => (x.opened = x.id === value.revisionId));
      state.revisions = revisions;
    }

    state.selectedVersion = cloneDeep(value);
    state.refreshVersionHistory++;
  }
};

export const actions: ActionTree<ProjectState, RootState> = {
  async cpqImport(
    { dispatch },
    { projectId, versionId, cpqSolutionId }: { projectId: string; versionId: string; cpqSolutionId: string }
  ): Promise<CrudResponse | undefined> {
    const response = await importService.importFromCpq(projectId, versionId, cpqSolutionId);

    if (response !== undefined) {
      await dispatch('updateVersionHistoryIfNeeded', response.currentVersion);
    }

    return response;
  },
  async createRevision({ commit }, payload: CreateRevisionRequest) {
    const revisions = await revisionService.createRevision(payload);
    commit(mutations.SET_REVISIONS.name, revisions);
  },
  async getProject({ commit, dispatch }, opportunityId: string): Promise<Project> {
    const project = await projectService.getProject(opportunityId);
    commit(mutations.SET_PROJECT.name, project);

    if (project.sfOpportunityId !== undefined && project.sfOpportunityId !== '') {
      await dispatch('updateOpportunityInfo', project.sfOpportunityId);
    }

    return project;
  },
  async getRevisions({ commit }, projectId: string): Promise<RevisionHistoryItem[]> {
    const revisions = await revisionService.getRevisions(projectId);
    commit(mutations.SET_REVISIONS.name, revisions);
    return revisions;
  },
  async revertVersion({ commit, dispatch }, payload: any) {
    const newVersion = await versionService.revertVersion(payload);
    commit(mutations.SET_SELECTED_VERSION.name, newVersion);
    await dispatch('getRevisions', payload.projectId);
    commit(mutations.INCREASE_REFRESH_VERSION.name);
  },
  async sizingProjectImport(
    { dispatch },
    { payload, createProject }: { payload: any; createProject: boolean }
  ): Promise<CrudResponse | undefined> {
    const response = await importService.uploadSizingProject(payload, createProject);

    if (response !== undefined && createProject === false) {
      await dispatch('updateVersionHistoryIfNeeded', response.currentVersion);
    }

    return response;
  },
  async standardExcelImport(
    { dispatch },
    { payload, createProject }: { payload: any; createProject: boolean }
  ): Promise<CrudResponse | undefined> {
    const response = await importService.uploadStandardExcel(payload, createProject);

    if (response !== undefined && createProject === false) {
      await dispatch('updateVersionHistoryIfNeeded', response.currentVersion);
    }

    return response;
  },
  async updateOpportunityInfo({ commit }, sfOpportunityId: string): Promise<OpportunityInfo | undefined> {
    const opportunityInfo = await projectService.getOpportunityInfo(sfOpportunityId);

    if (opportunityInfo) {
      commit(mutations.UPDATE_SELECTED_OPPORTUNITY_OPPORTUNITYINFO.name, opportunityInfo);
      return opportunityInfo;
    } else {
      return undefined;
    }
  },
  async updateRevision({ commit }, payload: UpdateRevisionRequest) {
    const revisions = await revisionService.updateRevision(payload);
    commit(mutations.SET_REVISIONS.name, revisions);
  },
  async updateVersionComment({ dispatch }, payload: any) {
    await versionService.updateVersionComment(payload);
    await dispatch('getRevisions', payload.projectId);
  },
  async updateVersionHistoryIfNeeded({ commit, dispatch }, currentVersion: Version) {
    const selectedVersion = state.selectedVersion;

    if (currentVersion.revisionId === selectedVersion?.revisionId && currentVersion.id !== selectedVersion?.id) {
      commit(mutations.SET_SELECTED_VERSION.name, currentVersion);
      await dispatch('getRevisions', selectedVersion.projectId);
    }
  }
};

export const project: Module<ProjectState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
