import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ConfigState, RootState } from '@/types';

const state: ConfigState = {
  baseUrl: undefined,
  pcrUrl: undefined
};

const getters: GetterTree<ConfigState, RootState> = {};

const mutations: MutationTree<ConfigState> = {
  CONFIGURE(state, value: ConfigState) {
    state.baseUrl = value.baseUrl;
    state.pcrUrl = value.pcrUrl;
  }
};

const actions: ActionTree<ConfigState, RootState> = {
  configure({ commit }, value: ConfigState) {
    commit(mutations.CONFIGURE.name, value);
  }
};

export const config: Module<ConfigState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
