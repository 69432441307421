import Axios, { AxiosStatic } from 'axios';
import { get } from 'lodash';
import { Format } from '@/services';
import { Entry, GasEntry, LiquidEntry, SerieEntry, SilDeviceType } from '@/types';
import { ReleaseNotes } from '@/types/ReleaseNotes';

export interface ValveParameters {
  ValveMotion?: string;
  Type?: string;
  Size?: number;
  PressureClass?: string;
  DesignStyle?: string;
  TrimOption?: string;
  SeatCode?: string;
  SizeReduction?: number;
}

export interface RotaryValveParameters {
  Series?: string;
  Size?: number;
  DesignStyle?: string;
  PressureClass?: string;
  PackingType?: string;
  BearingMaterial?: string;
  TrimPressureClass?: string;
  FlowDirection?: string;
  SeatType?: string;
  SeatAmount?: number;
}

class ListService {
  private http: AxiosStatic;
  private masterDataVersion: string = '';

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async getMasterdataVersion(): Promise<void> {
    const response = await this.http.get('/UILists/masterdataversion');
    this.masterDataVersion = response.data;
  }

  async valveTypes(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveTypes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async valveSeriesControl(params: ValveParameters = {}): Promise<SerieEntry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSeriesControl', {
      params: paramsAndVersion
    });
    return ListService.toSerieEntries(response);
  }

  async valveSeriesOnOff(params: ValveParameters = {}): Promise<SerieEntry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSeriesOnOff', {
      params: paramsAndVersion
    });
    return ListService.toSerieEntries(response);
  }

  async valveSizes(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSizes', {
      params: paramsAndVersion
    });
    return ListService.toSizeEntries(response);
  }

  async pressureClasses(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValvePressureClasses', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async schedules(params = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/PipelineSchedules', {
      params: paramsAndVersion
    });
    return ListService.toScheduleEntries(response);
  }

  async pipeSizes(params = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/PipelinePipeSizes', {
      params: paramsAndVersion
    });
    return ListService.toScheduleEntries(response);
  }

  async seatCodes(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSeatCodes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async sizeReductions(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSizeReductions', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async trimOptions(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveTrimOptions', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async designStyles(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveDesignStyles', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async flowDirections(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveFlowDirections', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async trimCharacteristics(params: ValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveTrimCharacteristics', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async seatTypes(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSeatTypes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async bearingMaterials(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveBearingMaterials', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async packingMaterials(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValvePackingMaterials', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async seatAmounts(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSeatAmounts', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async trimTypes(params: any = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveTrimTypes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async seatLeakages(params: any = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveSeatLeakages', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async bonnetTypes(params: any = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ValveBonnetTypes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async unitFactors(unitType: string, special?: string): Promise<Entry[]> {
    const params: any = { unitType };

    if (special) {
      params.special = special;
    }

    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/UnitFactors', {
      params: paramsAndVersion
    });
    return ListService.toUnitEntries(response);
  }

  async liquids(): Promise<LiquidEntry[]> {
    const paramsAndVersion = { masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/Liquids', {
      params: paramsAndVersion
    });
    return ListService.toLiquidEntries(response);
  }

  async gases(): Promise<GasEntry[]> {
    const paramsAndVersion = { masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/Gases', {
      params: paramsAndVersion
    });
    return ListService.toGasEntries(response);
  }

  async resistorTypes(params?: any): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ResistorTypes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async resistorSizes(params?: any): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ResistorSizes', {
      params: paramsAndVersion
    });
    return ListService.toSizeEntries(response);
  }

  async insulationTypes(): Promise<Entry[]> {
    const paramsAndVersion = { masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/PipelineInsulationTypes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async actuatorSeries(params: any = {}): Promise<SerieEntry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ActuatorSeries', {
      params: paramsAndVersion
    });
    return ListService.toSerieEntries(response);
  }

  async springActions(params: any = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ActuatorSpringActions', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async actuatorSizes(params: any = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ActuatorSizes', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async springRanges(params: any = {}): Promise<Entry[]> {
    const paramsAndVersion = { ...params, masterdataVersion: this.masterDataVersion };
    const response = await this.http.get('/UILists/ActuatorSpringRanges', {
      params: paramsAndVersion
    });
    return ListService.toEntries(response);
  }

  async silDeviceNames(deviceType: SilDeviceType): Promise<Entry[]> {
    const response = await this.http.get('/UILists/deviceNames', {
      params: { masterdataVersion: this.masterDataVersion, deviceType }
    });
    return ListService.toEntries(response);
  }

  async getReleaseNotes(): Promise<ReleaseNotes[]> {
    const response = await this.http.get('UILists/nelprof-release-notes');
    return response?.data;
  }

  private static toEntries(response: any): Entry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.item,
        tooltip: item.tooltip,
        defaultSelection: item.defaultSelection,
        active: item.active
      }));
  }

  private static toScheduleEntries(response: any): Entry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((entry: any) => ({
        value: entry.value,
        name: entry.name,
        active: entry.active
      }));
  }

  private static toSizeEntries(response: any): Entry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((entry: any) => ({
        value: entry.item,
        name: Format.size(entry.item)
      }));
  }

  private static toSerieEntries(response: any): SerieEntry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.item,
        motion: item.motion,
        type: item.type,
        imoLink: item.imoLink,
        bulletinLink: item.bulletinLink,
        active: item.active
      }));
  }

  private static toUnitEntries(response: any): Entry[] {
    return get(response, ['data', 'unitFactors'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({ name: item.unit }));
  }

  private static toLiquidEntries(response: any): LiquidEntry[] {
    return get(response, ['data', 'liquids'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.fluid,
        density: item.density,
        criticalPressure: item.criticalPressure,
        specificGravity: item.specificGravity,
        defaultSelection: item.defaultSelection
      }));
  }

  private static toGasEntries(response: any): GasEntry[] {
    return get(response, ['data', 'gases'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.fluid,
        molecularWeight: item.molecularWeight,
        specificHeats: item.specificHeats,
        defaultSelection: item.defaultSelection
      }));
  }
}

const listService = new ListService(Axios);

export { listService };
