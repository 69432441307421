import { area } from './area';
import { axiosPlugin, isEditedPlugin } from './plugins/';
import { chart } from './chart';
import { config } from './config';
import { createStore } from 'vuex';
import { error } from './error';
import { opportunity } from './opportunity';
import { printSheet } from './printSheet';
import { project } from './project';
import { RootState } from '@/types';
import { sidebar } from './sidebar';
import { sil } from './sil';
import { sizing } from './sizing';
import { sizingTree } from './sizingTree';
import { tag } from './tag';
import { units } from './units';

export default createStore<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    area,
    chart,
    config,
    error,
    opportunity,
    printSheet,
    project,
    sidebar,
    sil,
    sizing,
    sizingTree,
    tag,
    units
  },
  plugins: [axiosPlugin, isEditedPlugin]
});
