import { Store } from 'vuex';
import { fluidTypeToPropertyGroup, getFlowCasePropertyCount } from '@/services/FluidTypeSelection';
import { FluidPropertyGroup, FluidType } from '@/types';
import FlowCasePropertiesGas from '../components/FlowCase/FlowCasePropertiesGas.vue';
import FlowCasePropertiesLiquid from '../components/FlowCase/FlowCasePropertiesLiquid.vue';
import FlowCasePropertiesPulp from '../components/FlowCase/FlowCasePropertiesPulp.vue';
import FlowCasePropertiesTwoPhase from '../components/FlowCase/FlowCasePropertiesTwoPhase.vue';
import FlowCasePropertiesThirdPartyValve from '../components/FlowCase/FlowCasePropertiesThirdPartyValve.vue';

export default {
  propertyGroupToComponent(pg: FluidPropertyGroup): any {
    const propertyGroupByComponent: { [pg: string]: any } = {
      [FluidPropertyGroup.Gas]: FlowCasePropertiesGas,
      [FluidPropertyGroup.Liquid]: FlowCasePropertiesLiquid,
      [FluidPropertyGroup.Pulp]: FlowCasePropertiesPulp,
      [FluidPropertyGroup.TwoPhase]: FlowCasePropertiesTwoPhase,
      [FluidPropertyGroup.ThirdPartyValve]: FlowCasePropertiesThirdPartyValve
    };
    return propertyGroupByComponent[pg];
  },

  getFluidType(store: Store<any>): FluidType {
    const fluidType = store.state.sizing.valve.isThirdPartyValve
      ? FluidType.ThirdPartyValve
      : store.getters['sizing/fluidType'];
    return fluidType;
  },

  fluidPropertyGroups(store: Store<any>): FluidPropertyGroup[] {
    const fluidType = this.getFluidType(store);
    return fluidTypeToPropertyGroup(fluidType);
  },

  flowCaseSubcolumnInfos(store: Store<any>): any[] {
    const headingRows = 1;
    const ret = [];
    let accRowCount = 0;

    for (const pg of this.fluidPropertyGroups(store)) {
      const component = this.propertyGroupToComponent(pg);
      const rowCount = getFlowCasePropertyCount(pg);
      ret.push({
        component: component,
        startRowInParent: headingRows + accRowCount + 1,
        rowCount
      });
      accRowCount += rowCount;
    }

    return ret;
  },

  flowCaseHasSomeFilled(data: any, someSiblingFilled: boolean): boolean {
    return someSiblingFilled || this.hasSomeDataFilled(data);
  },

  hasSomeDataFilled(data: any): boolean {
    return Object.entries(data)
      .filter(([key]) => key !== 'caseId' && key !== 'caseGuid')
      .some(([_, value]) => this.isFilled(value));
  },

  isFilled(fieldContent: any): boolean {
    if (fieldContent === undefined) {
      return false;
    }

    if (typeof fieldContent === 'string' && fieldContent.trim() === '') {
      return false;
    }

    return true;
  }
};
