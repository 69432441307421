import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import { AreaDetails } from '@/types/SizingTree';
import { Area, AreaState, AsyncOperation, RootState } from '@/types';
import { cloneDeepExistingProperties, createProgressIndicatorAction } from '@/helpers';
import { areaService } from '@/services';

const initialSelectedArea = (): Area => {
  return {
    comment: '',
    areaId: undefined,
    opportunityId: undefined,
    areaName: ''
  };
};

export const initialState = (): AreaState => {
  return {
    selectedAreaId: undefined,
    selectedArea: initialSelectedArea()
  };
};

export const state: AreaState = initialState();

export const getters: GetterTree<AreaState, RootState> = {
  selectedArea(state): Area {
    return cloneDeep(state.selectedArea);
  }
};

export const mutations: MutationTree<AreaState> = {
  CHANGE_SELECTED_AREA(state: AreaState, areaId: string | undefined) {
    const areaChanged = areaId !== state.selectedAreaId;

    if (areaChanged || areaId == null) {
      Object.assign(state.selectedArea, initialSelectedArea());
    }

    state.selectedAreaId = areaId;
  },
  UPDATE_SELECTED_AREA_DATA(state: AreaState, value: Area) {
    state.selectedArea = value;
  },
  NEW_AREA_STARTED(state, opportunityId: string) {
    state.selectedArea = initialSelectedArea();
    state.selectedArea.opportunityId = opportunityId;
  },
  UPDATE_SELECTED_AREA_NAME(state: AreaState, value: string) {
    state.selectedArea.areaName = value;
  },
  UPDATE_SELECTED_AREA_COMMENT(state: AreaState, value: string) {
    state.selectedArea.comment = value;
  },
  AREA_DELETED(state, areaId) {
    if (areaId === state.selectedAreaId) {
      state.selectedAreaId = undefined;
      state.selectedArea = initialSelectedArea();
    }
  }
};

export const actions: ActionTree<AreaState, RootState> = {
  saveSelectedAreaWithProgressIndicator: createProgressIndicatorAction('saveSelectedArea', AsyncOperation.SavingArea),
  async saveSelectedArea({ state, getters, dispatch, rootState }) {
    if (state.selectedAreaId != null) {
      const selectedVersion = rootState.project?.selectedVersion;
      const response = await areaService.updateArea(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        getters.selectedArea
      );

      await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
        root: true
      });

      await dispatch('sizingTree/loadTreeWithProgressIndicator', true, {
        root: true
      });
    }
  },
  startCreatingNewArea({ commit }, opportunityId: string) {
    commit(mutations.NEW_AREA_STARTED.name, opportunityId);
  },
  changeSelectedArea({ commit }, areaId) {
    commit(mutations.CHANGE_SELECTED_AREA.name, areaId);
  },
  async refreshSelectedAreaData({ commit, state, getters, rootGetters, dispatch }) {
    await dispatch('sizingTree/loadTree', false, {
      root: true
    });

    if (state.selectedAreaId !== undefined) {
      const areaFromTree: AreaDetails | undefined = rootGetters['sizingTree/getAreaById'](state.selectedAreaId);

      if (areaFromTree !== undefined) {
        const updated: Area = cloneDeepExistingProperties(areaFromTree, getters.selectedArea);
        commit(mutations.UPDATE_SELECTED_AREA_DATA.name, updated);
      } else {
        dispatch('error/setError', 'The specified area was not found.', {
          root: true
        });
      }
    }
  },
  saveNewAreaWithProgressIndicator: createProgressIndicatorAction('saveNewArea', AsyncOperation.SavingArea),
  async saveNewArea({ dispatch, getters, rootState }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const response = await areaService.createArea(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      getters.selectedArea
    );

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    await dispatch('sizingTree/loadTreeWithProgressIndicator', true, {
      root: true
    });
  },
  deleteAreaWithProgressIndicator: createProgressIndicatorAction('deleteArea', AsyncOperation.DeletingArea),
  async deleteArea({ commit, dispatch, rootState }, areaId: string | undefined) {
    if (areaId !== undefined) {
      const selectedVersion = rootState.project?.selectedVersion;
      const response = await areaService.deleteArea(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        areaId
      );

      await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
        root: true
      });

      commit(mutations.AREA_DELETED.name, areaId);
      await dispatch('sizingTree/loadTree', true, {
        root: true
      });
    }
  }
};

export const area: Module<AreaState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
