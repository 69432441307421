import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { calculationService } from '@/services';
import { AsyncOperation, ChartState, RootState, ValveFunction } from '@/types';
import { createProgressIndicatorAction } from '@/helpers';

const state: ChartState = {
  chartData: null
};

export const getters: GetterTree<ChartState, RootState> = {};
export const mutations: MutationTree<ChartState> = {
  chartData_set(state, value: any) {
    state.chartData = value;
  },
  chartData_reset(state) {
    state.chartData = null;
  }
};

export const actions: ActionTree<ChartState, RootState> = {
  getChartDataWithProgressIndicator: createProgressIndicatorAction('getChartData', AsyncOperation.Calculating),
  async getChartData({ commit, rootGetters, rootState }, { opportunityId, sizingId }) {
    const valveFunction = rootGetters['sizing/valveFunction'];
    const isOnOff = valveFunction === ValveFunction.OnOff;
    const isControl = valveFunction === ValveFunction.Control;
    const hasValveResult = rootGetters['sizing/hasValveResults'];

    if (isOnOff || (isControl && !hasValveResult)) {
      return;
    }

    const selectedVersion = rootState.project?.selectedVersion;
    const result = await calculationService.getChartData(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      opportunityId,
      sizingId
    );

    if (result !== undefined) {
      commit(mutations.chartData_set.name, result);
    }
  },
  async exportChartData({ rootState }, { chartType }) {
    await calculationService.exportChartData(chartType, state.chartData);
  },
  async createChartData({ commit, rootGetters, rootState }) {
    commit(mutations.chartData_reset.name);

    if (!rootGetters['sizing/shouldCalculateCharts']) {
      return;
    }

    const sizingId = rootState.sizing.sizingId;
    const request = rootGetters['sizing/controlflowRequest'];

    const hasFlowcases = request?.flowCases && request?.flowCases?.length > 0;

    if (!sizingId || !request || !hasFlowcases) {
      return;
    }

    const selectedVersion = rootState.project?.selectedVersion;
    request.sizingId = sizingId;
    const result = await calculationService.createChartData(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      sizingId,
      request
    );

    if (result !== undefined) {
      commit(mutations.chartData_set.name, result);
    }
  },
  async saveChartData({ rootState }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const sizingId = rootState.sizing.sizingId;

    if (!state.chartData || state.chartData === '') {
      return;
    }

    if (sizingId !== undefined) {
      await calculationService.saveChartData(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        sizingId,
        state.chartData
      );
    }
  },
  async deleteChartDataIfCleared({ state, rootState }, sizingId: string) {
    if (state.chartData) {
      return;
    }

    const selectedVersion = rootState.project?.selectedVersion;
    await calculationService.deleteChartData(selectedVersion?.projectId ?? '', selectedVersion?.id ?? '', sizingId);
  },
  reset({ commit }) {
    commit(mutations.chartData_reset.name);
  }
};
export const chart: Module<ChartState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
