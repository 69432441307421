import Axios, { AxiosStatic } from 'axios';
import { DownloadHelper } from '@/helpers';

class ExportService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async downloadCpqExcel(request: any) {
    if (!request.sizingIds || request.sizingIds.length == 0) {
      return;
    }

    const response: any = await this.http.post(`export/cpqevoexport/`, request, { responseType: 'blob' });

    if (!response || !response.data) {
      return;
    }

    const blob = new Blob([response.data]);
    const fileName = DownloadHelper.getFilenameFromHeader(response.headers['content-disposition']);

    DownloadHelper.downloadFile(blob, fileName);
  }

  async downloadStandardExcel(request: any) {
    if (!request.sizingIds || request.sizingIds.length == 0) {
      return;
    }

    const response: any = await this.http.post(`export/standardexport/`, request, { responseType: 'blob' });

    if (!response || !response.data) {
      return;
    }

    const blob = new Blob([response.data]);
    const fileName = DownloadHelper.getFilenameFromHeader(response.headers['content-disposition']);

    DownloadHelper.downloadFile(blob, fileName);
  }

  async downloadSizingProject(request: any) {
    const response: any = await this.http.post(`export/sizingprojectexport/`, request, { responseType: 'blob' });

    if (!response || !response.data) {
      return;
    }

    const blob = new Blob([response.data]);
    const fileName = DownloadHelper.getFilenameFromHeader(response.headers['content-disposition']);

    DownloadHelper.downloadFile(blob, fileName);
  }
}
const exportService = new ExportService(Axios);

export { exportService };
