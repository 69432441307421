export enum AsyncOperation {
  Calculating = 'calculating',
  DeletingArea = 'deleteArea',
  DeletingOpportunity = 'deleteOpportunity',
  DeletingSizing = 'deleteSizing',
  DeletingSil = 'deleteSil',
  DeletingTag = 'deleteTag',
  DuplicatingSizing = 'duplicatingSizing',
  DuplicatingSil = 'duplicatingSil',
  DuplicatingTag = 'duplicatingTag',
  DuplicatingOpportunity = 'duplicateOpportunity',
  LoadingOpportunities = 'loadMyOpportunities',
  LoadingSil = 'LoadingSil',
  LoadingSizing = 'loadSizing',
  LoadingTree = 'loadTree',
  SavingArea = 'savingArea',
  SavingOpportunity = 'savingOpportunity',
  SavingSizing = 'savingSizing',
  SavingTag = 'savingTag',
  SavingSil = 'savingSil'
}
