import { FlowCaseResult } from '@/types/result/FlowCaseResult';
function hasNonZeroOrDefinedValues(obj: any, exclude: string[]): boolean {
  for (const key in obj) {
    if (!exclude.includes(key) && obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') {
        if (hasNonZeroOrDefinedValues(obj[key], exclude)) {
          return true;
        }
      } else if (obj[key] !== undefined && obj[key] !== 0 && obj[key] !== '0' && obj[key] !== '') {
        return true;
      }
    }
  }

  return false;
}
export function hasNonEmptyFlowCaseResults(flowcases: FlowCaseResult[]): boolean {
  for (let i = 0; i < flowcases?.length; i++) {
    const exclude = ['caseId', 'caseGuid'];

    if (hasNonZeroOrDefinedValues(flowcases[i], exclude)) {
      return true;
    }
  }

  return false;
}
