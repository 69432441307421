import Axios, { AxiosStatic } from 'axios';
import * as signalR from '@microsoft/signalr';
import { Store } from 'vuex';
import { sleep } from '@/helpers';

class SignalRService {
  private http: AxiosStatic;
  private hubConnection: signalR.HubConnection | undefined;
  private _store: Store<any> | undefined;
  private _timer: number = 0;
  private readonly _step: number = 100;
  private readonly _waitTime: number = 2000;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async initialize(store: Store<any>) {
    this._store = store;
  }

  async addVersionChangeListener(projectId: string) {
    try {
      if (this.hubConnection === undefined) {
        const connectionInfoResponse = await this.http.get('/signalR/negotiate');
        const connectionInfo = connectionInfoResponse.data;

        this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(connectionInfo.url, {
            accessTokenFactory: () => connectionInfo.accessToken
          })
          .withAutomaticReconnect()
          .build();

        this.hubConnection.on('versionChanged', (data) => {
          this.versionUpdates(data, this._store);
        });

        this.hubConnection.onreconnected(async (connectionId) => {
          await this.http.get(`/signalR/addToGroup?connectionId=${connectionId}&projectId=${projectId}`);
        });

        await this.startConnection();

        while (this._timer < this._waitTime) {
          if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            const connectionId = this.hubConnection.connectionId;
            await this.http.get(`/signalR/addToGroup?connectionId=${connectionId}&projectId=${projectId}`);

            this._timer = 0;
            break;
          } else {
            await sleep(this._step);
            this._timer += this._step;
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('addVersionChangeListener failed');
    }
  }

  private async startConnection() {
    if (this.hubConnection) {
      await this.hubConnection.start();
    }
  }

  private versionUpdates(data: any, store: Store<any> | undefined) {
    if (store) {
      const version = JSON.parse(data);
      store.commit('error/SET_ERROR', {
        alertType: 'info',
        error: `Version (${version.major}.${version.minor}) has been created or edited for project ${version.projectId}`
      });
    }
  }
}

const signalRService = new SignalRService(Axios);

export { signalRService };
