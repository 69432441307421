import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ErrorState, RootState, NpfError } from '@/types';

const state: ErrorState[] = [];

const getters: GetterTree<ErrorState[], RootState> = {
  hasError(state): boolean {
    return state.length > 0;
  },
  currentErrors(state): ErrorState[] {
    return state;
  }
};

const mutations: MutationTree<ErrorState[]> = {
  SET_ERROR(state: ErrorState[], value: NpfError) {
    const errorState: ErrorState = {
      error: value.error,
      statusCode: value.statusCode ? value.statusCode : undefined,
      correlationId: value.correlationId ? value.correlationId : undefined,
      alertType: value.alertType ? value.alertType : undefined
    };

    state.push(errorState);
  },
  CLEAR_ERROR(state: ErrorState[], index: number) {
    state.splice(index, 1);
  },
  CLEAR_ERRORS(state: ErrorState[]) {
    state.splice(0, state.length);
  }
};

const actions: ActionTree<ErrorState[], RootState> = {
  clearErrors({ getters, commit }) {
    if (getters.hasError) {
      commit(mutations.CLEAR_ERRORS.name);
    }
  },
  clearError({ getters, commit }, index: number) {
    if (getters.hasError) {
      commit(mutations.CLEAR_ERROR.name, index);
    }
  },
  setError({ commit }, error: NpfError) {
    commit(mutations.SET_ERROR.name, error);
  }
};

export const error: Module<ErrorState[], RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
