import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cloneDeep, sortBy } from 'lodash';
import Axios from 'axios';
import { AsyncOperation, DefaultUnits, Opportunity, OpportunityState, RootState } from '@/types';
import { createProgressIndicatorAction } from '@/helpers';
import { sizingTreeService } from '@/services';
import { defaultUnits } from './defaults/unitDefaults';

const initialSelectedOpportunity = (): Opportunity => {
  return {
    comment: '',
    cpqQuotationNumber: '',
    createTime: '',
    customer: '',
    customerReference: '',
    defaultUnits: cloneDeep(defaultUnits),
    id: '',
    nelesContact: '',
    opportunityDate: undefined,
    opportunityId: '',
    opportunityName: '',
    rfqNumber: '',
    salesforceId: '',
    sourceSystem: ''
  };
};

export const initialState = (): OpportunityState => {
  return {
    opportunitiesLoaded: false,
    myOpportunities: [],
    selectedOpportunityId: undefined,
    selectedOpportunity: initialSelectedOpportunity()
  };
};

export const state: OpportunityState = initialState();

export const getters: GetterTree<OpportunityState, RootState> = {
  initializedSelectedOpportunity(): Opportunity {
    return initialSelectedOpportunity();
  },
  selectedOpportunity(state): Opportunity {
    return cloneDeep(state.selectedOpportunity);
  }
};

export const mutations: MutationTree<OpportunityState> = {
  MY_OPPORTUNITIES_LIST_INVALIDATED(state) {
    state.opportunitiesLoaded = false;
    state.myOpportunities = [];
  },
  MY_OPPORTUNITIES_LOADED(state, value: Opportunity[]) {
    state.opportunitiesLoaded = true;
    state.myOpportunities = value;
  },
  SORT_MY_OPPORTUNITIES(state) {
    state.myOpportunities = sortBy(state.myOpportunities, ['createTime'], (o) => {
      if (o) {
        o.createTime = new Date(o.createTime!).toISOString().split('T')[0];
        o.opportunityName!.toLowerCase();
      }
    }).reverse();
  },
  CHANGE_SELECTED_OPPORTUNITY(state: OpportunityState, opportunityId: string | undefined) {
    const opportunityChanged = opportunityId !== state.selectedOpportunityId;

    if (opportunityChanged || opportunityId == null) {
      Object.assign(state.selectedOpportunity, initialSelectedOpportunity());
    }

    state.selectedOpportunityId = opportunityId;
  },
  CHANGE_SELECTED_OPPORTUNITY_ID(state: OpportunityState, opportunityId: string | undefined) {
    state.selectedOpportunityId = opportunityId;
  },
  UPDATE_SELECTED_OPPORTUNITY_DATA(state: OpportunityState, value: Opportunity) {
    state.selectedOpportunity = value;
  },
  NEW_OPPORTUNITY_STARTED(state) {
    state.selectedOpportunity = initialSelectedOpportunity();
  },
  UPDATE_SELECTED_OPPORTUNITY_NAME(state: OpportunityState, value: string) {
    state.selectedOpportunity.opportunityName = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_SALESFORCE_ID(state: OpportunityState, value: string) {
    state.selectedOpportunity.salesforceId = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_COMMENT(state: OpportunityState, value: string) {
    state.selectedOpportunity.comment = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_CUSTOMER(state: OpportunityState, value: string) {
    state.selectedOpportunity.customer = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_NELES_CONTACT(state: OpportunityState, value: string) {
    state.selectedOpportunity.nelesContact = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_OPPORTUNITY_DATE(state: OpportunityState, value: Date) {
    state.selectedOpportunity.opportunityDate = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_CPQ_QUOTATION_NUMBER(state: OpportunityState, value: string) {
    state.selectedOpportunity.cpqQuotationNumber = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_RFQ_NUMBER(state: OpportunityState, value: string) {
    state.selectedOpportunity.rfqNumber = value;
  },
  UPDATE_SELECTED_OPPORTUNITY_CUSTOMER_REFERENCE(state: OpportunityState, value: string) {
    state.selectedOpportunity.customerReference = value;
  },
  DELETE_OPPORTUNITY(state, opportunityId: string) {
    const index = state.myOpportunities.findIndex((o) => o.opportunityId == opportunityId);
    state.myOpportunities = state.myOpportunities.slice(index, 1);
  },
  UPDATE_SELECTED_OPPORTUNITY_DEFAULT_UNITS(state: OpportunityState, value: DefaultUnits) {
    state.selectedOpportunity.defaultUnits = value;
  }
};

export const actions: ActionTree<OpportunityState, RootState> = {
  loadMyOpportunitiesWithProgressIndicator: createProgressIndicatorAction(
    'loadMyOpportunities',
    AsyncOperation.LoadingOpportunities
  ),
  async loadMyOpportunities({ commit }) {
    const result = await Axios.get('/opportunity');
    commit(mutations.MY_OPPORTUNITIES_LOADED.name, result.data);
    commit(mutations.SORT_MY_OPPORTUNITIES.name);
  },
  saveSelectedOpportunityWithProgressIndicator: createProgressIndicatorAction(
    'saveSelectedOpportunity',
    AsyncOperation.SavingOpportunity
  ),
  async saveSelectedOpportunity({ state, commit, dispatch, rootState }) {
    const selectedVersion = rootState.project?.selectedVersion;

    if (selectedVersion?.projectId !== undefined) {
      await sizingTreeService.saveOpportunity(selectedVersion?.projectId, state.selectedOpportunity);
      await dispatch('sizingTree/loadTreeWithProgressIndicator', true, {
        root: true
      });
      commit(mutations.MY_OPPORTUNITIES_LIST_INVALIDATED.name);
    }
  },
  startCreatingNewOpportunity({ commit, rootGetters }) {
    commit(mutations.NEW_OPPORTUNITY_STARTED.name);

    const defaultUnits = rootGetters['units/defaultUnits'];
    commit(mutations.UPDATE_SELECTED_OPPORTUNITY_DEFAULT_UNITS.name, defaultUnits);
  },
  changeSelectedOpportunity({ commit }, opportunityId) {
    commit(mutations.CHANGE_SELECTED_OPPORTUNITY.name, opportunityId);
  },
  async refreshSelectedOpportunityData({ state, dispatch }) {
    await dispatch('sizingTree/changeRootOpportunity', state.selectedOpportunityId, {
      root: true
    });
    await dispatch('sizingTree/loadTreeWithProgressIndicator', true, {
      root: true
    });
  },
  saveNewOpportunityWithProgressIndicator: createProgressIndicatorAction(
    'saveNewOpportunity',
    AsyncOperation.SavingOpportunity
  ),
  async saveNewOpportunity({ commit, getters }) {
    const newOpportunityId = await sizingTreeService.createOpportunity(getters.selectedOpportunity);
    commit(mutations.MY_OPPORTUNITIES_LIST_INVALIDATED.name);
    commit(mutations.CHANGE_SELECTED_OPPORTUNITY.name, newOpportunityId);
  },
  deleteOpportunityWithProgressIndicator: createProgressIndicatorAction(
    'deleteOpportunity',
    AsyncOperation.DeletingOpportunity
  ),
  async deleteOpportunity({ commit }, opportunityId) {
    await Axios.delete(`/opportunity/${opportunityId}`);
    commit(mutations.DELETE_OPPORTUNITY.name, opportunityId);
    commit(mutations.MY_OPPORTUNITIES_LIST_INVALIDATED.name);
  },
  duplicateOpportunityWithProgressIndicator: createProgressIndicatorAction(
    'duplicateOpportunity',
    AsyncOperation.DuplicatingOpportunity
  ),
  async duplicateOpportunity({ commit }, { projectId, versionId, name }): Promise<string> {
    const newProjectId = await sizingTreeService.duplicateOpportunity(projectId, versionId, name);
    commit(mutations.MY_OPPORTUNITIES_LIST_INVALIDATED.name);
    return newProjectId;
  }
};

export const opportunity: Module<OpportunityState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
