import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import { createVueWait } from 'vue-wait-vue3';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faClone,
  faFileExport,
  faFileImport,
  faGears,
  faPlusCircle,
  faSave,
  faTrash,
  faCopy,
  faPaste,
  faSortAlphaDown,
  faSortNumericDownAlt,
  faPrint,
  faExclamationTriangle,
  faCheckSquare,
  faMinusSquare,
  faShareNodes,
  faCheckCircle,
  faSortNumericUpAlt,
  faSortAlphaUp,
  faCircleInfo,
  faTriangleExclamation,
  faExclamation,
  faQuestion,
  faPlusSquare,
  faCaretRight,
  faClipboard,
  faSort,
  faArrowDown,
  faArrowUp
} from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCircle, faDotCircle, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { authenticationService } from '@/services/AuthenticationService';
import vuetify from './plugins/vuetify';
import { signalRService } from '@/services/SignalRService';
import { loadRuntimeConfiguration, runtimeConfiguration } from '@/plugins/runtimeConfiguration';
import mitt from 'mitt';
import { baseName, fixPrefix, kebabCase } from './register-npf-components';
import 'bootstrap'; // required import for bootstrap

library.add(
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCheck,
  faClone,
  faFileExport,
  faFileImport,
  faGears,
  faPlusCircle,
  faSave,
  faTrash,
  faCopy,
  faPaste,
  faSortNumericUpAlt,
  faSortNumericDownAlt,
  faSortAlphaDown,
  faSortAlphaUp,
  faPrint,
  faExclamationTriangle,
  faSquare,
  faCheckSquare,
  faMinusSquare,
  faShareNodes,
  faCheckCircle,
  faCircleInfo,
  faTriangleExclamation,
  faExclamation,
  faQuestion,
  faPlusSquare,
  faClipboard,
  faCircle,
  faDotCircle,
  faSort,
  faArrowDown,
  faArrowUp,
  faCommentDots
);

const vueWait = createVueWait({
  useVuex: true
});
const runtimeConfigurationOptions = await loadRuntimeConfiguration();
const emitter = mitt();
const app = createApp(App);
app.use(store);
app.use(router);
app.use(vueWait);
app.use(vuetify);
app.use(runtimeConfiguration, runtimeConfigurationOptions);
const context = require.context('@/components/Common', true, /Npf[A-Z]\w+\.(vue|ts|js)$/);
context.keys().forEach((fileName: string) => {
  const config = context(fileName);
  const componentName = kebabCase(fixPrefix(baseName(fileName)));

  if (componentName) {
    app.component(componentName, config.default || config);
  }
});
app.component('font-awesome-icon', FontAwesomeIcon);
app.provide('emitter', emitter);
authenticationService.setup(store, router);
app.mount('#app');
signalRService.initialize(store);
