import Axios, { AxiosStatic } from 'axios';
import { Tag } from '@/types';
import { CrudResponse } from '@/types/response/crudResponse';

class TagService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async createTag(projectId: string, versionId: string, tag: Tag): Promise<CrudResponse> {
    const response = await this.http.post(`/tag/${projectId}/${versionId}`, tag);
    return response.data;
  }

  async updateTag(projectId: string, versionId: string, tag: Tag): Promise<CrudResponse> {
    const response = await this.http.put(`/tag/${projectId}/${versionId}/${tag.tagId}`, tag);
    return response.data;
  }

  async duplicateTag(projectId: string, versionId: string, areaId: string, tagId: string): Promise<CrudResponse> {
    const response = await this.http.post(`/tag/${projectId}/${versionId}/${areaId}/${tagId}/copy`);
    return response.data;
  }

  async deleteTag(projectId: string, versionId: string, areaId: string, tagId: string): Promise<CrudResponse> {
    const response = await this.http.delete(`/tag/${projectId}/${versionId}/${areaId}/${tagId}`);
    return response.data;
  }

  async selectTagSizing(
    projectId: string,
    versionId: string,
    areaId: string,
    tagId: string,
    sizingId: string
  ): Promise<CrudResponse> {
    const response = await this.http.put(`/tag/${projectId}/${versionId}/${areaId}/${tagId}/sizing`, {
      sizingId
    });
    return response.data;
  }
}

const tagService = new TagService(Axios);

export { tagService };
