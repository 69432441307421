import Axios, { AxiosStatic } from 'axios';
import { get } from 'lodash';
import { OpportunityDetails } from '@/types/SizingTree';
import { Opportunity } from '@/types';
import { signalRService } from './SignalRService';

class SizingTreeService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async getIdsForTree(opportunityId: string): Promise<OpportunityDetails> {
    const result = await this.http.get(`/opportunity/${opportunityId}/sizingtree`);
    return result.data;
  }

  async loadTree(
    opportunityId: string,
    projectId: string | undefined,
    versionId: string | undefined
  ): Promise<OpportunityDetails> {
    const result =
      projectId !== undefined && versionId !== undefined
        ? await this.http.get(`/opportunity/${opportunityId}/${projectId}/${versionId}`)
        : await this.http.get(`/opportunity/${opportunityId}`);

    if (!this.isPublicEnvironment()) {
      signalRService.addVersionChangeListener(result.data.id);
    }

    return result.data;
  }

  async createOpportunity(opportunity: Opportunity): Promise<void> {
    const result = await this.http.post('/opportunity', opportunity);
    return get(result, ['data']);
  }

  async saveOpportunity(projectId: string, opportunity: Opportunity): Promise<void> {
    await this.http.put(`/opportunity/${projectId}`, opportunity);
  }

  async duplicateOpportunity(projectId: string, versionId: string, name: string): Promise<string> {
    const result = await this.http.post(`/opportunity/${projectId}/${versionId}/${name}/copy`);
    return get(result, ['data', 'projectId']);
  }

  private isPublicEnvironment() {
    return window.location.hostname === 'nelprof.valmet.com';
  }
}

const sizingTreeService = new SizingTreeService(Axios);

export { sizingTreeService };
