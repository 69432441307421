import { inject } from 'vue';

export const LiquidChangedEvent: string = 'liquidChanged';
export const GasChangedEvent: string = 'gasChanged';
export const PressureChanged: string = 'pressureChanged';
export const ShowSnackbarEvent: string = 'showSnackbar';
export const ShowDialogEvent: string = 'showDialog';
export const ToggleVersionHistoryEvent: string = 'toggleVersionHistory';

export interface INpfEventBus {
  dispatch: <T>(eventName: string, payload: T) => void;
  subscribe: (eventName: string, callback: Function) => void;
  unsubscribe: (eventName: string, callback: Function) => void;
  unsubscribeAll: (eventName: string) => void;
}

export class NpfEventBus implements INpfEventBus {
  private emitter: any;

  constructor() {
    this.emitter = inject('emitter');

    if (!this.emitter) {
      throw new Error('emitter was not provided');
    }
  }

  public dispatch<T = void>(eventName: string, payload: T) {
    if (!payload) {
      this.emitter.emit(eventName);
    } else {
      this.emitter.emit(eventName, payload);
    }
  }

  public subscribe(eventName: string, callback: Function): void {
    if (!eventName || eventName.length === 0) {
      throw new Error('Event name was not specified');
    }

    if (!callback) {
      throw new Error('callback is null');
    }

    this.emitter.on(eventName, callback);
  }

  public unsubscribe(eventName: string, callback: Function) {
    this.emitter.off(eventName, callback);
  }

  public unsubscribeAll() {
    this.emitter.all.clear();
  }
}
