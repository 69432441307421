import { ActionContext, DispatchOptions } from 'vuex';
import { AsyncOperation } from '@/types';

export const createProgressIndicatorAction =
  <S, R>(
    action: string,
    asyncOp: AsyncOperation
  ): ((actionContext: ActionContext<S, R>, payload?: any, options?: DispatchOptions) => Promise<any>) =>
  async (context: ActionContext<S, R>, payload?: any, options?: DispatchOptions): Promise<any> => {
    await context.dispatch('wait/start', asyncOp, { root: true });
    try {
      return await context.dispatch(action, payload, options);
    } finally {
      await context.dispatch('wait/end', asyncOp, { root: true });
    }
  };
