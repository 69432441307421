import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SidebarState } from '@/types';

export const state: SidebarState = {
  isOpen: false
};

export const getters: GetterTree<SidebarState, RootState> = {};

export const mutations: MutationTree<SidebarState> = {
  TOGGLE_SIDEBAR(state) {
    state.isOpen = !state.isOpen;
  },
  HIDE_SIDEBAR(state) {
    state.isOpen = false;
  }
};

export const actions: ActionTree<SidebarState, RootState> = {
  toggleSidebar({ commit }) {
    commit(mutations.TOGGLE_SIDEBAR.name);
  },
  hideSidebar({ commit }) {
    commit(mutations.HIDE_SIDEBAR.name);
  }
};

export const sidebar: Module<SidebarState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
