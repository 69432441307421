import Axios, { AxiosStatic } from 'axios';

export class PrintSheetService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async getPrintSheet(request: any): Promise<any> {
    const response = await this.http.post(`/printsheet/printSheetDataAsPdf`, request, { responseType: 'blob' });

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'Nelprof.pdf';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }
}

const printSheetService = new PrintSheetService(Axios);

export { printSheetService };
