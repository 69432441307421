import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import { TagDetails, AreaDetails } from '@/types/SizingTree';
import { AsyncOperation, RootState, Tag, TagState, ValveFunction } from '@/types';
import { cloneDeepExistingProperties, createProgressIndicatorAction } from '@/helpers';
import { tagService } from '@/services';

const initialSelectedTag = (): Tag => {
  return {
    comment: '',
    tagId: undefined,
    areaId: undefined,
    tagName: '',
    tagType: undefined,
    tagNumber: 1,
    customerInquiryOriginalOrder: 0
  };
};

export const initialState = (): TagState => {
  return {
    selectedTagId: undefined,
    selectedTag: initialSelectedTag()
  };
};

export const state: TagState = initialState();

export const getters: GetterTree<TagState, RootState> = {
  selectedTag(state): Tag {
    return cloneDeep(state.selectedTag);
  }
};

export const mutations: MutationTree<TagState> = {
  CHANGE_SELECTED_TAG(state: TagState, tagId: string | undefined) {
    const tagChanged = tagId !== state.selectedTagId;

    if (tagChanged || tagId == null) {
      Object.assign(state.selectedTag, initialSelectedTag());
    }

    state.selectedTagId = tagId;
  },
  UPDATE_SELECTED_TAG_DATA(state: TagState, value: Tag) {
    state.selectedTag = value;
  },
  NEW_TAG_STARTED(
    state,
    { areaId, tagType, tagNumber }: { areaId: string; tagType: ValveFunction; tagNumber: number }
  ) {
    state.selectedTag = initialSelectedTag();
    state.selectedTag.areaId = areaId;
    state.selectedTag.tagType = tagType;
    state.selectedTag.tagNumber = tagNumber;
  },
  UPDATE_SELECTED_TAG_NAME(state: TagState, value: string) {
    state.selectedTag.tagName = value;
  },
  UPDATE_SELECTED_TAG_NUMBER(state: TagState, value: number) {
    state.selectedTag.tagNumber = value;
  },
  UPDATE_SELECTED_TAG_COMMENT(state: TagState, value: string) {
    state.selectedTag.comment = value;
  },
  TAG_DELETED(state, tagId) {
    if (tagId === state.selectedTagId) {
      state.selectedTagId = undefined;
      state.selectedTag = initialSelectedTag();
    }
  }
};

export const actions: ActionTree<TagState, RootState> = {
  saveSelectedTagWithProgressIndicator: createProgressIndicatorAction('saveSelectedTag', AsyncOperation.SavingTag),
  async saveSelectedTag({ state, getters, dispatch, rootState }) {
    if (state.selectedTagId != null) {
      const selectedVersion = rootState.project?.selectedVersion;
      const response = await tagService.updateTag(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        getters.selectedTag
      );

      await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
        root: true
      });

      await dispatch('sizingTree/loadTreeWithProgressIndicator', true, {
        root: true
      });
    }
  },
  startCreatingNewTag({ commit, rootGetters }, { areaId, tagType }) {
    const rootOpportunity = rootGetters['sizingTree/rootOpportunity'];
    const tagNumbers = rootOpportunity?.areas?.flatMap((area: AreaDetails) =>
      area.tags.map((tag: TagDetails) => tag.tagNumber ?? 0)
    );
    const tagNumber = tagNumbers?.length > 0 ? Math.max(...tagNumbers) + 1 : 1;

    commit(mutations.NEW_TAG_STARTED.name, { areaId, tagType, tagNumber });
  },
  changeSelectedTag({ commit }, tagId) {
    commit(mutations.CHANGE_SELECTED_TAG.name, tagId);
  },
  async refreshSelectedTagData({ commit, state, getters, rootGetters, dispatch }) {
    if (state.selectedTagId !== undefined) {
      const tagFromTree: TagDetails | undefined = rootGetters['sizingTree/getTagById'](state.selectedTagId);

      if (tagFromTree !== undefined) {
        const updated: Tag = cloneDeepExistingProperties(tagFromTree, getters.selectedTag);
        commit(mutations.UPDATE_SELECTED_TAG_DATA.name, updated);
      } else {
        await dispatch('error/setError', 'The specified tag was not found.', {
          root: true
        });
      }
    }
  },
  saveNewTagWithProgressIndicator: createProgressIndicatorAction('saveNewTag', AsyncOperation.SavingTag),
  async saveNewTag({ commit, dispatch, getters, rootState }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const response = await tagService.createTag(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      getters.selectedTag
    );

    const tag = getters.selectedTag;
    tag.tagId = response.content;
    commit(mutations.UPDATE_SELECTED_TAG_DATA.name, tag);

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    await dispatch('sizing/createNewSizing', getters.selectedTag, {
      root: true
    });
  },
  duplicateTagWithProgressIndicator: createProgressIndicatorAction('duplicateTag', AsyncOperation.DuplicatingTag),
  async duplicateTag({ dispatch, rootState }, { areaId, tagId }) {
    if (tagId) {
      const selectedVersion = rootState.project?.selectedVersion;
      const response = await tagService.duplicateTag(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        areaId,
        tagId
      );

      await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
        root: true
      });

      await dispatch('sizingTree/loadTree', true, {
        root: true
      });
    }
  },
  deleteTagWithProgressIndicator: createProgressIndicatorAction('deleteTag', AsyncOperation.DeletingTag),
  async deleteTag({ commit, dispatch, rootState }, { areaId, tagId }) {
    if (tagId !== undefined) {
      const selectedVersion = rootState.project?.selectedVersion;
      const response = await tagService.deleteTag(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        areaId,
        tagId
      );
      commit(mutations.TAG_DELETED.name, tagId);

      await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
        root: true
      });

      await dispatch('sizingTree/loadTree', true, {
        root: true
      });
    }
  },
  async selectTagSizing({ dispatch, rootState }, { areaId, tagId, sizingId }) {
    if (tagId !== undefined) {
      const selectedVersion = rootState.project?.selectedVersion;
      const response = await tagService.selectTagSizing(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        areaId,
        tagId,
        sizingId
      );

      await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
        root: true
      });

      await dispatch('sizingTree/loadTree', true, {
        root: true
      });
    }
  }
};

export const tag: Module<TagState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
