import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { PrintSheetState, RootState } from '@/types';
import { printSheetService } from '@/services';

const state: PrintSheetState = {
  selectedCharts: [],
  showSafetyfactor: false,
  showWarnings: false,
  language: 'English'
};

export const getters: GetterTree<PrintSheetState, RootState> = {};

export const mutations: MutationTree<PrintSheetState> = {
  SET_SELECTED_CHARTS(state, value: any) {
    state.selectedCharts = value;
  },
  SET_SHOW_SAFETY_FACTOR(state, value: any) {
    state.showSafetyfactor = value;
  },
  SET_SHOW_WARNINGS(state, value: any) {
    state.showWarnings = value;
  },
  SET_LANGUAGE(state, value: any) {
    state.language = value;
  }
};

const actions: ActionTree<PrintSheetState, RootState> = {
  async getPrintSheet({ rootState }, { sizingIds, silIds }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const request: {
      projectId?: string;
      versionId?: string;
      sizingIds?: string[];
      silIds?: string[];
      selectedCharts?: string[];
      printSheetOptions?: string[];
      language?: string;
    } = {
      projectId: selectedVersion?.projectId,
      versionId: selectedVersion?.id,
      sizingIds: sizingIds.value,
      silIds: silIds.value,
      selectedCharts: state.selectedCharts,
      printSheetOptions: [],
      language: state.language
    };

    if (state.showSafetyfactor) {
      request.printSheetOptions?.push('ShowSafetyFactor');
    }

    if (state.showWarnings) {
      request.printSheetOptions?.push('ShowWarnings');
    }

    await printSheetService.getPrintSheet(request);
  }
};

export const printSheet: Module<PrintSheetState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
