import { ActionContext, DispatchOptions, Store } from 'vuex';
import { RootState } from '@/types';

const sizingIsEdited = async (store: Store<RootState>, mutation: string, isEditedAuto: boolean) => {
  if (!isEditedAuto) {
    return;
  }

  let dispatchIsEdited = false;
  let resetResults = false;
  switch (mutation) {
    case 'isEdited':
    case 'notEdited':
    case 'editing_enableAutoDetect':
    case 'editing_disableAutoDetect':
    case 'editing_disableAutoDetectStackDecreased':
    case 'reload':
    case 'result_reset':
    case 'actuator_result_reset':
    case 'flowCase_validity_set': //Redundant with flowCase_edit
    case 'flowCase_edit': //isEdited handled in action editFlowCase
    case 'sizingId_set':
      dispatchIsEdited = false;
      resetResults = false;
      break;
    case 'sizingName_set':
    case 'processMedium_description':
    case 'internalComment_set':
    case 'externalComment_set':
      dispatchIsEdited = true;
      resetResults = false;
      break;
    case 'sizingId_reset':
      dispatchIsEdited = false;
      resetResults = true;
      break;
    default:
      dispatchIsEdited = true;
      resetResults = true;
      break;
  }

  if (dispatchIsEdited) {
    store.commit('sizing/isEdited');
  }

  if (resetResults) {
    store.commit('sizing/result_reset');
    store.commit('sizing/actuator_result_reset');
    store.commit('chart/chartData_reset');
  }
};

const silIsEdited = (store: Store<RootState>, mutation: string, isEditedAuto: boolean) => {
  if (!isEditedAuto) {
    return;
  }

  let dispatchIsEdited = false;
  let resetResults = false;
  switch (mutation) {
    case 'isEdited':
    case 'notEdited':
    case 'editing_enableAutoDetect':
    case 'editing_disableAutoDetect':
    case 'editing_disableAutoDetectStackDecreased':
    case 'result_reset':
    case 'silId_set':
      dispatchIsEdited = false;
      resetResults = false;
      break;
    case 'silName_set':
      dispatchIsEdited = true;
      resetResults = false;
      break;
    case 'silId_reset':
      dispatchIsEdited = false;
      resetResults = true;
      break;
    default:
      dispatchIsEdited = true;
      resetResults = true;
      break;
  }

  if (dispatchIsEdited) {
    store.commit('sil/isEdited');
  }

  if (resetResults) {
    store.commit('sil/result_reset');
  }
};

export const isEditedPlugin = (store: Store<RootState>) => {
  store.subscribe(({ type }, state) => {
    const [storeModule, mutation] = type.split('/');
    switch (storeModule) {
      case 'sizing':
        sizingIsEdited(store, mutation, state[storeModule].isEditingAutoDetected);
        break;
      case 'sil':
        silIsEdited(store, mutation, state[storeModule].isEditingAutoDetected);
        break;
      default:
        break;
    }
  });
};

export const createActionWithUndetectedEdit =
  <S, R>(
    action: string,
    storeModule?: string
  ): ((actionContext: ActionContext<S, R>, payload?: any, options?: DispatchOptions) => Promise<any>) =>
  async (context: ActionContext<S, R>, payload?: any, options?: DispatchOptions): Promise<any> => {
    let opts: any = undefined;
    let modulePrefix: string = '';

    if (storeModule) {
      opts = { root: true };
      modulePrefix = `${storeModule}/`;
    }

    context.commit(`${modulePrefix}editing_disableAutoDetect`, undefined, opts);
    context.commit(`${modulePrefix}editing_disableAutoDetectStackIncreased`, undefined, opts);

    try {
      return await context.dispatch(action, payload, options);
    } finally {
      const isEditedState = context.state as unknown as IsEditedState;
      context.commit(`${modulePrefix}editing_disableAutoDetectStackDecreased`, undefined, opts);

      if (isEditedState.editingAutoDetectDisabledCount <= 0) {
        context.commit(`${modulePrefix}editing_enableAutoDetect`, undefined, opts);
      }
    }
  };

export const isEditedMutations = {
  isEdited<T extends IsEditedState>(state: T) {
    state.isEdited = true;
  },
  notEdited<T extends IsEditedState>(state: T) {
    state.isEdited = false;
  },
  editing_enableAutoDetect<T extends IsEditedState>(state: T) {
    state.isEditingAutoDetected = true;
  },
  editing_disableAutoDetect<T extends IsEditedState>(state: T) {
    state.isEditingAutoDetected = false;
  },
  editing_disableAutoDetectStackIncreased<T extends IsEditedState>(state: T) {
    state.editingAutoDetectDisabledCount += 1;
  },
  editing_disableAutoDetectStackDecreased<T extends IsEditedState>(state: T) {
    state.editingAutoDetectDisabledCount -= 1;
  }
};

export interface IsEditedState {
  isEdited: boolean;
  isEditingAutoDetected: boolean;
  editingAutoDetectDisabledCount: number;
}
