export interface ImportJob {
  CompletedOn: string | undefined;
  CorrelationId: string | undefined;
  FriendlyError: string | undefined;
  OpportunityId: string | undefined;
  RequestedByUserId: string | undefined;
  RequestdOn: string | undefined;
  StartedOn: string | undefined;
  Status: ImportJobStatus;
  WarningsJson: string | undefined;
}

export enum ImportJobStatus {
  NotStarted = 0,
  Started = 1,
  Succeeded = 2,
  Failed = 3
}
