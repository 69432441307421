const inchesByMillimeters: Map<number, number> = new Map([
  [10, 0.375],
  [15, 0.5],
  [20, 0.75],
  [25, 1],
  [32, 1.25],
  [40, 1.5],
  [50, 2],
  [65, 2.5],
  [80, 3]
]);

const millimetersByInches: Map<number, number> = new Map(
  Array.from(inchesByMillimeters.entries(), ([key, value]) => [value, key])
);

const Convert = {
  millimetersToInches(value: number | undefined): number | undefined {
    if (!value && value !== 0) {
      return undefined;
    }

    return inchesByMillimeters.get(value) || parseFloat((value / 25).toFixed(3));
  },
  inchesToMillimeters(value: number | undefined): number | undefined {
    if (!value && value !== 0) {
      return undefined;
    }

    return millimetersByInches.get(value) || value * 25;
  },
  toTrimReduction(reduction: number | undefined): string | undefined {
    if (typeof reduction !== 'number') {
      return undefined;
    }

    return reduction === 0 ? 'Full capacity' : `${reduction}-Step reduction`;
  }
};

export { Convert };
