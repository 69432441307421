import {
  FlowCase,
  FluidPropertyGroup,
  FluidType,
  GasFlowCase,
  LiquidFlowCase,
  PulpFlowCase,
  TwoPhaseFlowCase,
  ThirdPartyValveFlowCase
} from '@/types';

export const emptyFlowCase = (
  flowCaseType: FluidType,
  caseId?: string | undefined,
  caseGuid?: string | undefined
): FlowCase | GasFlowCase | LiquidFlowCase | PulpFlowCase | TwoPhaseFlowCase | ThirdPartyValveFlowCase => {
  switch (flowCaseType) {
    case FluidType.Liquid: {
      return {
        caseId: caseId,
        caseGuid: caseGuid,
        liquidFlowRate: undefined,
        inletTemperature: undefined,
        inletPressure: undefined,
        pressureDifference: undefined,
        outletPressure: undefined,
        vapourPressure: undefined,
        kinematicViscosity: undefined,
        dynamicViscosity: undefined,
        liquidDensity: undefined
      } as LiquidFlowCase;
    }
    case FluidType.Pulp: {
      return {
        caseId: caseId,
        caseGuid: caseGuid,
        liquidFlowRate: undefined,
        inletTemperature: undefined,
        inletPressure: undefined,
        pressureDifference: undefined,
        outletPressure: undefined
      } as PulpFlowCase;
    }
    case FluidType.Gas: {
      return {
        caseId: caseId,
        caseGuid: caseGuid,
        inletTemperature: undefined,
        inletPressure: undefined,
        pressureDifference: undefined,
        outletPressure: undefined,
        gasFlowRate: undefined,
        compressibility: undefined,
        inletDensity: undefined
      } as GasFlowCase;
    }
    case FluidType.TwoPhase: {
      return {
        caseId: caseId,
        caseGuid: caseGuid,
        liquidFlowRate: undefined,
        inletTemperature: undefined,
        inletPressure: undefined,
        pressureDifference: undefined,
        outletPressure: undefined,
        vapourPressure: undefined,
        gasFlowRate: undefined,
        compressibility: undefined,
        liquidDensity: undefined,
        inletDensity: undefined
      } as TwoPhaseFlowCase;
    }
    case FluidType.None: {
      return {
        caseId: caseId,
        caseGuid: caseGuid,
        inletTemperature: undefined,
        inletPressure: undefined,
        pressureDifference: undefined,
        outletPressure: undefined
      } as FlowCase;
    }
    case FluidType.ThirdPartyValve: {
      return {
        caseId: caseId,
        caseGuid: caseGuid,
        inletTemperature: undefined,
        inletPressure: undefined,
        pressureDifference: undefined,
        outletPressure: undefined,
        travel: undefined,
        runToClose: undefined,
        runToOpen: undefined
      } as ThirdPartyValveFlowCase;
    }
    default:
      throw Error(`Not implemented: ${flowCaseType}`);
  }
};

const typeToProperty: { [type: string]: FluidPropertyGroup[] } = {
  [FluidType.Liquid]: [FluidPropertyGroup.Liquid],
  [FluidType.Pulp]: [FluidPropertyGroup.Pulp],
  [FluidType.Gas]: [FluidPropertyGroup.Gas],
  [FluidType.TwoPhase]: [FluidPropertyGroup.TwoPhase],
  [FluidType.ThirdPartyValve]: [FluidPropertyGroup.ThirdPartyValve]
};

export const fluidTypeToPropertyGroup = (type: FluidType): FluidPropertyGroup[] => {
  if (!(type in typeToProperty)) {
    return [FluidPropertyGroup.Liquid];
  }

  return typeToProperty[type];
};

const fluidPropertyGroupToFlowCaseProperties: { [pg: string]: string[] } = {
  [FluidPropertyGroup.Common]: ['inletTemperature', 'inletPressure', 'pressureDifference', 'outletPressure'],
  [FluidPropertyGroup.Pulp]: [
    'inletTemperature',
    'inletPressure',
    'pressureDifference',
    'outletPressure',
    'liquidFlowRate'
  ],
  [FluidPropertyGroup.Liquid]: [
    'inletTemperature',
    'inletPressure',
    'pressureDifference',
    'outletPressure',
    'liquidFlowRate',
    'vapourPressure',
    'liquidDensity',
    'kinematicViscosity',
    'dynamicViscosity'
  ],
  [FluidPropertyGroup.Gas]: [
    'inletTemperature',
    'inletPressure',
    'pressureDifference',
    'outletPressure',
    'gasFlowRate',
    'compressibility',
    'inletDensity'
  ],
  [FluidType.TwoPhase]: [
    'inletTemperature',
    'inletPressure',
    'pressureDifference',
    'outletPressure',
    'liquidFlowRate',
    'vapourPressure',
    'gasFlowRate',
    'compressibility',
    'inletDensity',
    'liquidDensity'
  ],
  [FluidType.ThirdPartyValve]: ['travel', 'runToClose', 'runToOpen']
};

export const getFlowCaseProperties = (propertyGroup: FluidPropertyGroup): string[] => {
  return fluidPropertyGroupToFlowCaseProperties[propertyGroup];
};

const fluidPropertyGroupPropertyCount: { [pg: string]: number } = {
  [FluidPropertyGroup.Gas]: 6,
  [FluidPropertyGroup.Liquid]: 8,
  [FluidPropertyGroup.Pulp]: 5,
  [FluidPropertyGroup.TwoPhase]: 9,
  [FluidPropertyGroup.ThirdPartyValve]: 3
};

export const getFlowCasePropertyCount = (propertyGroup: FluidPropertyGroup): number => {
  return fluidPropertyGroupPropertyCount[propertyGroup];
};
