import { createVuetify } from 'vuetify/lib/framework.mjs';
import { aliases, fa } from 'vuetify/iconsets/fa-svg';

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme: {
        variables: {
          'theme-overlay-multiplier': 0.32,
          'hover-opacity': 0.2,
          'theme-background': '#f0f1f6'
        },
        colors: {
          surface: '#FFFFFF',
          'theme-background': '#f0f1f6',
          background: '#f0f1f6'
        }
      }
    }
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa
    }
  }
});
