import type { App, Plugin } from 'vue';

export interface RuntimeConfigurationOptions {
  variables: any;
}

export const runtimeConfiguration: Plugin = {
  install: (app: App, options: RuntimeConfigurationOptions) => {
    app.provide('runtimeConfiguration', options.variables);
  }
};

/**
 * Loads runtime configuration from static file (in /public folder).
 */
export const loadRuntimeConfiguration = async (): Promise<RuntimeConfigurationOptions> => {
  const resp = await fetch('/runtime-config.json');
  const value = await resp.json();
  return {
    variables: value.browser as any
  } as RuntimeConfigurationOptions;
};
