import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cloneDeep, get } from 'lodash';
import { createProgressIndicatorAction } from '@/helpers';
import { createActionWithUndetectedEdit, isEditedMutations } from '@/store/plugins';
import {
  Architecture,
  AsyncOperation,
  DiagnosticCoverage,
  PhysicalQuantity,
  RootState,
  SafetyPosition,
  SilCalculationRequest,
  SilDevice,
  SilDeviceRequest,
  SilDeviceType,
  SilResult,
  SilSaveRequest,
  SilState,
  TestIntervals,
  SilCoefficients
} from '@/types';
import { calculationService, silService } from '@/services';
import { Time } from '@/types/units';
import { CrudResponse } from '@/types/response/crudResponse';
import { TagDetails } from '@/types/SizingTree';

const instrumentCount = 4;
const intelligentPstCount = 1;
const accessoryCount = instrumentCount + intelligentPstCount;
const instrumentDefaultArchitecture = Architecture.Architecture1oo1;
const valveId = 'Valve';
const actuatorId = 'Actuator';
const intelligentPstIndex = 0;
const firstInstrumentIndex = 1;

export const state: SilState = {
  tagId: undefined,
  silId: undefined,
  silName: 'SIL',
  diagnosticCoverage: DiagnosticCoverage.None,
  safetyPosition: SafetyPosition.Close,
  testIntervals: {
    fullStrokeTest: 24,
    fullStrokeTestUnit: Time.Month,
    partialStrokeTest: 3,
    partialStrokeTestUnit: Time.Month,
    pneumaticTest: 7,
    pneumaticTestUnit: Time.Day
  },
  valve: initialValve(),
  actuator: initialActuator(),
  accessories: initialAccessories(instrumentCount),
  result: undefined,
  isEdited: false,
  isEditingAutoDetected: true,
  editingAutoDetectDisabledCount: 0
};

export const getters: GetterTree<SilState, RootState> = {
  valve(state): SilDevice {
    return cloneDeep(state.valve);
  },
  result(state): SilResult | undefined {
    return cloneDeep(state.result);
  },
  actuator(state): SilDevice {
    return cloneDeep(state.actuator);
  },
  accessories(state): SilDevice[] {
    return cloneDeep(state.accessories);
  },
  isEdited: (state): boolean => state.isEdited,
  calculationRequest(state, getters): SilCalculationRequest {
    const accessories = getters.accessories as SilDevice[];
    const request: SilCalculationRequest = {
      safetyPosition: state.safetyPosition,
      diagnosticCoverage: state.diagnosticCoverage,
      tiFst: getters.tiFstRequest,
      devices: [getDeviceRequest(getters.valve, state.safetyPosition)]
    };

    if (getters.actuator.architecture !== Architecture.None) {
      request.devices = [...request.devices, getDeviceRequest(getters.actuator, state.safetyPosition)];
    }

    request.devices = [
      ...request.devices,
      ...accessories
        .filter((a) => {
          return (
            a.architecture !== Architecture.None &&
            (a.deviceType !== SilDeviceType.IntelligentPst ||
              state.diagnosticCoverage === DiagnosticCoverage.ValveGuard)
          );
        })
        .map((a) => getDeviceRequest(a, state.safetyPosition))
    ];

    if (
      state.diagnosticCoverage === DiagnosticCoverage.Manual ||
      state.diagnosticCoverage === DiagnosticCoverage.ValveGuard
    ) {
      request.tiPst = getters.tiPstRequest;
    }

    if (state.diagnosticCoverage === DiagnosticCoverage.ValveGuard) {
      request.tiPneu = getters.tiPneuRequest;
    }

    return request;
  },
  isDiagnostic(state): boolean {
    const architecture = get(state, ['valve', 'architecture']);
    return architecture ? isDiagnosticArchitecture(architecture) : false;
  },
  isValveGuard(state): boolean {
    return state.diagnosticCoverage === DiagnosticCoverage.ValveGuard;
  },
  saveRequest(state, getters): SilSaveRequest {
    return {
      silId: state.silId,
      tagId: state.tagId,
      silName: state.silName,
      calculation: getters.calculationRequest,
      result: state.result
    };
  },
  tiFstRequest(state): PhysicalQuantity | undefined {
    if (state.testIntervals.fullStrokeTest !== undefined && state.testIntervals.fullStrokeTestUnit !== undefined) {
      return {
        value: state.testIntervals.fullStrokeTest,
        unit: state.testIntervals.fullStrokeTestUnit
      };
    }

    return undefined;
  },
  tiPstRequest(state): PhysicalQuantity | undefined {
    if (
      state.testIntervals.partialStrokeTest !== undefined &&
      state.testIntervals.partialStrokeTestUnit !== undefined
    ) {
      return {
        value: state.testIntervals.partialStrokeTest,
        unit: state.testIntervals.partialStrokeTestUnit
      };
    }

    return undefined;
  },
  tiPneuRequest(state): PhysicalQuantity | undefined {
    if (state.testIntervals.pneumaticTest !== undefined && state.testIntervals.pneumaticTestUnit !== undefined) {
      return {
        value: state.testIntervals.pneumaticTest,
        unit: state.testIntervals.pneumaticTestUnit
      };
    }

    return undefined;
  }
};

export const mutations: MutationTree<SilState> = {
  ...isEditedMutations,
  silId_set(state, value: string) {
    state.silId = value;
  },
  silId_reset(state) {
    state.silId = undefined;
  },
  diagnosticCoverage(state, value: DiagnosticCoverage) {
    state.diagnosticCoverage = value;
  },
  diagnosticCoverage_reset(state) {
    state.diagnosticCoverage = DiagnosticCoverage.None;
  },
  safetyPosition(state, value: SafetyPosition) {
    state.safetyPosition = value;
  },
  safetyPosition_reset(state) {
    state.safetyPosition = SafetyPosition.Close;
  },
  fullStrokeTestInterval(state, value: number) {
    state.testIntervals.fullStrokeTest = value;
  },
  fullStrokeTestUnit(state, value: Time) {
    state.testIntervals.fullStrokeTestUnit = value;
  },
  partialStrokeTestInterval(state, value: number) {
    state.testIntervals.partialStrokeTest = value;
  },
  partialStrokeTestUnit(state, value: Time) {
    state.testIntervals.partialStrokeTestUnit = value;
  },
  pneumaticTestInterval(state, value: number) {
    state.testIntervals.pneumaticTest = value;
  },
  pneumaticTestUnit(state, value: Time) {
    state.testIntervals.pneumaticTestUnit = value;
  },
  valve_set(state, value: SilDevice) {
    state.valve = { ...value };

    if (state.actuator.architecture !== Architecture.None) {
      state.actuator.architecture = value.architecture;
    }
  },
  valveCoefficients_set(
    state,
    { value, keepUserInputs }: { value: SilCoefficients | undefined; keepUserInputs: boolean }
  ) {
    const valve = { ...state.valve };
    valve.coefficients = value;
    valve.lambdaD = value ? value.lambdaD : undefined;

    if (keepUserInputs) {
      valve.mttr = state.valve.mttr;
    } else {
      valve.mttr = value ? value.mttrDefaultValue : undefined;
    }

    state.valve = valve;
  },
  valve_reset(state) {
    state.valve = initialValve();
  },
  actuator_set(state, value: SilDevice) {
    const newValue = { ...value };
    newValue.architecture = value.deviceName ? state.valve.architecture : Architecture.None;
    state.actuator = newValue;
  },
  actuatorCoefficients_set(
    state,
    { value, keepUserInputs }: { value: SilCoefficients | undefined; keepUserInputs: boolean }
  ) {
    const actuator = { ...state.actuator };
    actuator.coefficients = value;
    actuator.lambdaD = value ? value.lambdaD : undefined;

    if (keepUserInputs) {
      actuator.mttr = state.actuator.mttr;
    } else {
      actuator.mttr = value ? value.mttrDefaultValue : undefined;
    }

    state.actuator = actuator;
  },
  actuator_reset(state) {
    state.actuator = initialActuator();
  },
  accessory_set(state, { value, index }: { value: SilDevice; index: number }) {
    if (index < 0 || index >= accessoryCount) {
      return;
    }

    state.accessories.splice(index, 1, { ...value });
  },
  accessoryCoefficients_set(
    state,
    { value, index, keepUserInputs }: { value: SilCoefficients; index: number; keepUserInputs: boolean }
  ) {
    if (index < 0 || index >= accessoryCount) {
      return;
    }

    const accessory = { ...state.accessories[index] };
    accessory.coefficients = value;
    accessory.lambdaD = value ? value.lambdaD : undefined;

    if (keepUserInputs) {
      accessory.mttr = state.accessories[index].mttr;
    } else {
      accessory.mttr = value ? value.mttrDefaultValue : undefined;
    }

    state.accessories.splice(index, 1, accessory);
  },
  accessories_reset(state) {
    state.accessories = initialAccessories(instrumentCount);
  },
  result_set(state, value: SilResult) {
    state.result = Object.assign({}, value);

    if (value.devices !== undefined) {
      const valveResult = value.devices.find((resultDevice) => resultDevice.deviceId === valveId);

      if (valveResult) {
        state.valve = { ...state.valve, pfdAvg: valveResult.pfdAvg };
      }

      const actuatorResult = value.devices.find((resultDevice) => resultDevice.deviceId === actuatorId);

      if (actuatorResult) {
        state.actuator = { ...state.actuator, pfdAvg: actuatorResult.pfdAvg };
      }

      value.devices.forEach((resultDevice) => {
        const accessoryIndex = state.accessories.findIndex((accessory) => accessory.deviceId === resultDevice.deviceId);

        if (accessoryIndex >= 0) {
          state.accessories.splice(accessoryIndex, 1, {
            ...state.accessories[accessoryIndex],
            pfdAvg: resultDevice.pfdAvg
          });
        }
      });
    }
  },
  result_reset(state) {
    state.result = undefined;
    state.valve.pfdAvg = undefined;
    state.actuator.pfdAvg = undefined;
    state.accessories.forEach((accessory) => (accessory.pfdAvg = undefined));
  },
  silName_set(state, value: string) {
    state.silName = value;
  },
  silName_reset(state) {
    state.silName = 'SIL';
  },
  tagId_set(state, value: string) {
    state.tagId = value;
  },
  tagId_reset(state) {
    state.tagId = undefined;
  },
  testIntervals_reset(state) {
    const intervals: TestIntervals = {
      fullStrokeTest: 24,
      fullStrokeTestUnit: Time.Month,
      partialStrokeTest: 3,
      partialStrokeTestUnit: Time.Month,
      pneumaticTest: 7,
      pneumaticTestUnit: Time.Day
    };
    state.testIntervals = intervals;
  }
};

export const actions: ActionTree<SilState, RootState> = {
  calculateWithProgressIndicator: createProgressIndicatorAction(
    'calculateWithoutDetectingEdits',
    AsyncOperation.Calculating
  ),
  calculateWithoutDetectingEdits: createActionWithUndetectedEdit('calculate'),
  async calculate({ commit, getters }) {
    commit(mutations.result_reset.name);
    const result = await calculationService.calculateSil(getters.calculationRequest);

    if (result) {
      commit(mutations.result_set.name, result);
      commit('isEdited');
    }
  },
  loadWithProgressIndicator: createProgressIndicatorAction('loadWithoutDetectingEdits', AsyncOperation.LoadingSil),
  loadWithoutDetectingEdits: createActionWithUndetectedEdit('loadSil', 'sil'),
  async loadSil({ commit, dispatch, rootState }, { opportunityId, silId }) {
    await dispatch('resetSil');
    const selectedVersion = rootState.project?.selectedVersion;
    const sil = await silService.read(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      opportunityId,
      silId
    );

    if (!sil) {
      return;
    }

    const { tagId, silName, calculation, result } = sil;

    if (tagId) {
      commit(mutations.tagId_set.name, tagId);
    }

    if (silId) {
      commit(mutations.silId_set.name, silId);
    }

    if (silName) {
      commit(mutations.silName_set.name, silName);
    }

    if (calculation) {
      const { safetyPosition, diagnosticCoverage, tiFst, tiPst, tiPneu, devices } = calculation;
      commit(mutations.safetyPosition.name, safetyPosition);
      commit(mutations.diagnosticCoverage.name, diagnosticCoverage);

      if (tiFst) {
        commit(mutations.fullStrokeTestInterval.name, tiFst.value);
        commit(mutations.fullStrokeTestUnit.name, tiFst.unit);
      }

      if (tiPst) {
        commit(mutations.partialStrokeTestInterval.name, tiPst.value);
        commit(mutations.partialStrokeTestUnit.name, tiPst.unit);
      }

      if (tiPneu) {
        commit(mutations.pneumaticTestInterval.name, tiPneu.value);
        commit(mutations.pneumaticTestUnit.name, tiPneu.unit);
      }

      if (devices && Array.isArray(devices)) {
        const coefficientFetches: Promise<void>[] = [];

        const valve = devices.find((d) => d.deviceId === valveId);

        if (valve) {
          commit(mutations.valve_set.name, valve);
          coefficientFetches.push(dispatch('fetchValveCoefficients', { keepUserInputs: true }));
        }

        const actuator = devices.find((d) => d.deviceId === actuatorId);

        if (actuator) {
          commit(mutations.actuator_set.name, actuator);
          coefficientFetches.push(dispatch('fetchActuatorCoefficients', { keepUserInputs: true }));
        }

        const intelligentPst = devices.find((d) => d.deviceType === SilDeviceType.IntelligentPst);

        if (intelligentPst) {
          commit(mutations.accessory_set.name, {
            index: intelligentPstIndex,
            value: intelligentPst
          });
          coefficientFetches.push(
            dispatch('fetchAccessoryCoefficients', {
              index: intelligentPstIndex,
              keepUserInputs: true
            })
          );
        } else {
          commit(mutations.accessory_set.name, {
            index: intelligentPstIndex,
            value: initialIntelligentPst()
          });
        }

        const firstInstrumentResultIndex = devices.findIndex((d) => d.deviceType === SilDeviceType.Instrument);
        const loadedInstruments = firstInstrumentResultIndex > 0 ? devices.slice(firstInstrumentResultIndex) : [];

        const instruments = fillSkippedInstruments(instrumentCount, loadedInstruments);

        instruments.forEach((instrument, index) => {
          const accessoryIndex = index + firstInstrumentIndex;
          commit(mutations.accessory_set.name, {
            index: accessoryIndex,
            value: instrument
          });
          coefficientFetches.push(
            dispatch('fetchAccessoryCoefficients', {
              index: accessoryIndex,
              keepUserInputs: true
            })
          );
        });

        await Promise.all(coefficientFetches);
      }
    }

    commit(mutations.result_reset.name, result);

    if (result) {
      commit(mutations.result_set.name, result);
    }

    commit(isEditedMutations.notEdited.name);
  },
  deleteSilWithProgressIndicator: createProgressIndicatorAction('deleteSil', AsyncOperation.DeletingSil),
  async deleteSil({ state, commit, dispatch, rootState }, silId) {
    if (state.silId === silId) {
      commit('notEdited');
    }

    const selectedVersion = rootState.project?.selectedVersion;
    const response = await silService.delete(selectedVersion?.projectId ?? '', selectedVersion?.id ?? '', silId);

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    if (state.silId === silId) {
      await dispatch('resetSil');
    }

    await dispatch('sizingTree/loadTree', true, {
      root: true
    });
  },
  resetSil({ commit }) {
    commit(mutations.silId_reset.name);
    commit(mutations.silName_reset.name);
    commit(mutations.diagnosticCoverage_reset.name);
    commit(mutations.safetyPosition_reset.name);
    commit(mutations.testIntervals_reset.name);
    commit(mutations.valve_reset.name);
    commit(mutations.actuator_reset.name);
    commit(mutations.accessories_reset.name);
    commit(mutations.result_reset.name);
    commit(isEditedMutations.notEdited.name);
  },
  duplicateSilWithProgressIndicator: createProgressIndicatorAction('duplicateSil', AsyncOperation.DuplicatingSil),
  async duplicateSil({ dispatch, rootState }, silId: string) {
    const selectedVersion = rootState.project?.selectedVersion;
    const response = await silService.copy(selectedVersion?.projectId ?? '', selectedVersion?.id ?? '', silId);

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    await dispatch('sizingTree/loadTree', true, {
      root: true
    });
  },
  async createNewSil({ dispatch, commit }, tag: TagDetails) {
    await dispatch('resetSil');
    const tagId = tag.tagId ?? '';
    commit('tagId_set', tagId);
    const silId = await dispatch('saveWithProgressIndicator');
    commit(mutations.silId_set.name, silId);
  },
  saveWithProgressIndicator: createProgressIndicatorAction('saveWithoutDetectingEdits', AsyncOperation.SavingSil),
  saveWithoutDetectingEdits: createActionWithUndetectedEdit('save', 'sil'),
  async save({ dispatch, commit, getters, rootState, state }) {
    const isCreate = !state.silId;
    const selectedVersion = rootState.project?.selectedVersion;
    let response: CrudResponse;

    if (isCreate) {
      response = await silService.create(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        getters.saveRequest
      );
      commit('silId_set', response.content);
    } else {
      response = await silService.update(
        selectedVersion?.projectId ?? '',
        selectedVersion?.id ?? '',
        getters.saveRequest
      );
    }

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    await dispatch('sizingTree/loadTree', true, {
      root: true
    });

    commit('notEdited');

    return isCreate ? response.content : undefined;
  },
  async fetchValveCoefficients({ commit, state }, { keepUserInputs }: { keepUserInputs: boolean }) {
    let coefficients: SilCoefficients | undefined;

    if (!state.valve.deviceName || !state.valve.deviceType) {
      coefficients = undefined;
    } else {
      coefficients = await silService.getSilCoefficients(state.valve.deviceName, state.valve.deviceType);
    }

    commit('valveCoefficients_set', { value: coefficients, keepUserInputs });
  },
  async fetchActuatorCoefficients({ commit, state }, { keepUserInputs }: { keepUserInputs: boolean }) {
    let coefficients: SilCoefficients | undefined;

    if (!state.actuator.deviceName || !state.actuator.deviceType) {
      coefficients = undefined;
    } else {
      coefficients = await silService.getSilCoefficients(state.actuator.deviceName, state.actuator.deviceType);
    }

    commit('actuatorCoefficients_set', { value: coefficients, keepUserInputs });
  },
  async fetchAccessoryCoefficients(
    { state, commit },
    { index, keepUserInputs }: { index: number; keepUserInputs: boolean }
  ) {
    if (index < 0 || index >= accessoryCount) {
      return;
    }

    const deviceName = state.accessories[index].deviceName;
    const deviceType = state.accessories[index].deviceType;
    let coefficients: SilCoefficients | undefined;

    if (!deviceName || !deviceType) {
      coefficients = undefined;
    } else {
      coefficients = await silService.getSilCoefficients(deviceName, deviceType);
    }

    commit('accessoryCoefficients_set', {
      value: coefficients,
      index,
      keepUserInputs
    });
  },
  setAccessoryAutoArchitecture({ state, commit }, index) {
    if (index < 0 || index >= accessoryCount) {
      return;
    }

    const accessory = state.accessories[index];
    let architecture = accessory.architecture;

    if (accessory.deviceType === SilDeviceType.IntelligentPst) {
      return;
    }

    if (accessory.architecture === Architecture.None) {
      architecture = instrumentDefaultArchitecture;
    } else if (!accessory.deviceName) {
      architecture = Architecture.None;
    }

    const updatedAccessory = {
      ...accessory,
      architecture
    };
    commit('accessory_set', { value: updatedAccessory, index });
  },
  setValve({ commit, getters }, value: SilDevice) {
    const oldArchitecture = getters.valve.architecture;
    const architectureChanged = oldArchitecture !== value.architecture;

    if (architectureChanged) {
      const previouslyDiagnostic = isDiagnosticArchitecture(oldArchitecture);
      const isDiagnostic = isDiagnosticArchitecture(value.architecture);

      if (previouslyDiagnostic && !isDiagnostic) {
        commit(mutations.diagnosticCoverage.name, DiagnosticCoverage.None);
      } else if (!previouslyDiagnostic && isDiagnostic) {
        commit(mutations.diagnosticCoverage.name, DiagnosticCoverage.ValveGuard);
      }
    }

    commit(mutations.valve_set.name, value);
  },
  setDiagnosticCoverage({ commit, getters }, value: DiagnosticCoverage) {
    const convertToDiagnostic = value !== DiagnosticCoverage.None;
    const architecture = getCounterPartArchitecture(getters.valve.architecture, convertToDiagnostic);
    commit(mutations.valve_set.name, { ...getters.valve, architecture });
    commit(mutations.actuator_set.name, { ...getters.actuator, architecture });
    commit(mutations.diagnosticCoverage.name, value);
  }
};

export const sil: Module<SilState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

function getDeviceRequest(device: SilDevice, safetyPosition: SafetyPosition): SilDeviceRequest {
  return {
    deviceId: device.deviceId,
    architecture: device.architecture,
    deviceType: device.deviceType,
    deviceName: device.deviceName,
    mttr: device.mttr,
    DC: safetyPosition === SafetyPosition.Close ? device.coefficients?.dcClose : device.coefficients?.dcOpen,
    lambdaD: device.coefficients?.lambdaD
  };
}

function initialSilDevice(deviceId: string): SilDevice {
  return {
    deviceId,
    architecture: Architecture.None,
    deviceName: undefined,
    deviceType: undefined,
    lambdaD: undefined,
    mttr: undefined,
    pfdAvg: undefined,
    coefficients: undefined
  };
}

function initialValve(): SilDevice {
  return {
    ...initialSilDevice(valveId),
    deviceType: SilDeviceType.Valve,
    architecture: Architecture.Architecture1oo1
  };
}

function initialActuator(): SilDevice {
  return {
    ...initialSilDevice(actuatorId),
    deviceType: SilDeviceType.Actuator,
    architecture: Architecture.None
  };
}

function initialInstrument(index: number): SilDevice {
  return {
    ...initialSilDevice(`Instrument ${index + 1}`),
    deviceType: SilDeviceType.Instrument
  };
}

function initialIntelligentPst(): SilDevice {
  return {
    ...initialSilDevice(`Intelligent PST`),
    deviceType: SilDeviceType.IntelligentPst,
    architecture: Architecture.Architecture1oo1D
  };
}

function initialAccessories(count: number): SilDevice[] {
  const instruments = [...Array(count).keys()].map((i) => initialInstrument(i));
  return [initialIntelligentPst(), ...instruments];
}

function isDiagnosticArchitecture(architecture: Architecture) {
  switch (architecture) {
    case Architecture.None:
    case Architecture.Architecture1oo1:
    case Architecture.Architecture1oo2:
    case Architecture.Architecture2oo2:
      return false;
    case Architecture.Architecture1oo1D:
      return true;
    default:
      throw Error('Unknown architecture');
  }
}

function getCounterPartArchitecture(architecture: string, convertToDiagnostic: boolean): string {
  switch (architecture) {
    case Architecture.None:
      return Architecture.None;
    case Architecture.Architecture1oo1:
      return convertToDiagnostic ? Architecture.Architecture1oo1D : architecture;
    case Architecture.Architecture1oo2:
      return convertToDiagnostic
        ? Architecture.Architecture1oo1D //1oo2D not implemented yet
        : architecture;
    case Architecture.Architecture2oo2:
      return convertToDiagnostic
        ? Architecture.Architecture1oo1D //2oo2D not implemented yet
        : architecture;
    case Architecture.Architecture1oo1D:
      return convertToDiagnostic ? architecture : Architecture.Architecture1oo1;
    default:
      throw Error('Unknown architecture');
  }
}

function fillSkippedInstruments(expectedDeviceCount: number, originalDevices: SilDevice[]): SilDevice[] {
  const devices: SilDevice[] = [];

  for (let i = 0; i < expectedDeviceCount; i++) {
    const device = originalDevices.find(
      (d) => i + 1 === parseInt(get(d, ['deviceId'], '').replace('Instrument ', ''), 10)
    );

    if (device !== undefined) {
      devices.push(device);
    } else {
      devices.push(initialInstrument(i));
    }
  }

  return devices;
}
