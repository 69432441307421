import { UIComponentSize } from '@/types';

export default {
  getUIComponentSizes(uiComponentSizes: UIComponentSize): object {
    const sizes: any = {};

    if (uiComponentSizes.width) {
      sizes.width = uiComponentSizes.width;
    }

    if (uiComponentSizes.height) {
      sizes.height = uiComponentSizes.height;
    }

    if (uiComponentSizes.minWidth) {
      sizes['min-width'] = uiComponentSizes.minWidth;
    }

    if (uiComponentSizes.maxWidth) {
      sizes['max-width'] = uiComponentSizes.maxWidth;
    }

    if (uiComponentSizes.minHeight) {
      sizes['min-height'] = uiComponentSizes.minHeight;
    }

    if (uiComponentSizes.maxHeight) {
      sizes['max-height'] = uiComponentSizes.maxHeight;
    }

    return sizes;
  }
};
