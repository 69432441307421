export function baseName(pathName: string): string {
  const fileName = pathName.split('/').pop();
  return fileName ? fileName.replace(/\.\w+$/, '') : '';
}

export function fixPrefix(value: string): string {
  return value.replace(/^Npf/, 'npf');
}

export function kebabCase(value: string) {
  const pattern = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

  if (!value) {
    return undefined;
  }

  const matches = value.match(pattern);

  return matches ? matches.map((segment) => segment.toLowerCase()).join('-') : undefined;
}
