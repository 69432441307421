import { FluidType } from '../FluidType';
import { Option } from '../Option';

const defaultFluidTypes: Option[] = [
  { value: FluidType.Liquid, name: 'Liquid' },
  { value: FluidType.Gas, name: 'Gas' },
  { value: FluidType.TwoPhase, name: 'Two phase' },
  { value: FluidType.Pulp, name: 'Pulp' }
];

export { defaultFluidTypes };
