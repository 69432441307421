<template>
  <div class="npf-grid">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
$npf-grid-heading-row-height: 2.35rem;
$npf-grid-row-height: calc(1.4345rem + 2px); //to match row height in bootstrap-based grids

.npf-grid {
  display: grid;
  background-color: white;
  grid-auto-columns: 16.66%;
  grid-gap: 0;

  .npf-grid-column {
    display: grid;
    grid-template-rows: $npf-grid-heading-row-height;
    grid-auto-rows: $npf-grid-row-height;
  }

  .npf-grid-subcolumn {
    display: grid;
    grid-auto-rows: $npf-grid-row-height;
  }

  .npf-grid-item {
    background-color: white;
    border: 0 solid $border-color;
    border-right-width: 1px;
    border-bottom-width: 1px;
    position: relative;

    div {
      width: 100%;
    }

    &.label {
      display: flex;
      align-items: center;
      align-self: stretch;
      color: $txt-color;
      padding: 0 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.heading {
      border-right-width: 0;
      padding-right: 0;
      height: $npf-grid-heading-row-height;
      padding-bottom: 0.3em;
      padding-top: 0.3em;

      > div {
        display: flex;
        align-items: center;
        border-width: 0;
        height: 1.75rem;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}
</style>
