import { Convert } from '.';

const Format = {
  size(millimeters: number): string | undefined {
    if (!millimeters && millimeters !== 0) {
      return undefined;
    }

    let value = `${millimeters} mm`;

    const inches = Convert.millimetersToInches(millimeters);

    if (inches) {
      value += ` / ${inches} "`;
    }

    return value;
  },
  toInches(inches: number): string | undefined {
    if (!inches && inches !== 0) {
      return undefined;
    }

    const mm = Convert.inchesToMillimeters(inches);
    const value = `${mm} mm`;

    return value + ` / ${inches} "`;
  }
};

export { Format };
