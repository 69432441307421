import Axios, { AxiosStatic } from 'axios';
import { isEmpty } from 'lodash';
import { SilDeviceType, SilSaveRequest, SilCoefficients } from '@/types';
import { CrudResponse } from '@/types/response/crudResponse';

class SilService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async create(projectId: string, versionId: string, sil: SilSaveRequest): Promise<CrudResponse> {
    if (!sil.tagId) {
      throw new Error('Missing tagId');
    }

    const response = await this.http.post(`/sil/${projectId}/${versionId}`, sil);
    return response.data;
  }

  async read(projectId: string, versionId: string, opportunityId: string, silId: string): Promise<any> {
    if (!silId) {
      throw new Error('Missing silId');
    }

    const url =
      !isEmpty(projectId) && !isEmpty(versionId)
        ? `/sil/${projectId}/${versionId}/${silId}`
        : `/sil/${opportunityId}/${silId}`;

    const response = await this.http.get(url);
    return response.data;
  }

  async update(projectId: string, versionId: string, sil: SilSaveRequest): Promise<CrudResponse> {
    if (!sil.tagId) {
      throw new Error('Missing tagId');
    }

    if (!sil.silId) {
      throw new Error('Missing silId');
    }

    const response = await this.http.put(`/sil/${projectId}/${versionId}/${sil.silId}`, sil);
    return response.data;
  }

  async delete(projectId: string, versionId: string, silId: string): Promise<CrudResponse> {
    if (!silId) {
      throw new Error('Missing silId');
    }

    const response = await this.http.delete(`/sil/${projectId}/${versionId}/${silId}`);
    return response.data;
  }

  async copy(projectId: string, versionId: string, silId: string): Promise<CrudResponse> {
    if (!silId) {
      throw new Error('Missing silId');
    }

    const response = await this.http.post(`/sil/${projectId}/${versionId}/${silId}/copy`);
    return response.data;
  }

  async getSilCoefficients(deviceName: string, deviceType: SilDeviceType): Promise<SilCoefficients> {
    if (!deviceName) {
      throw Error('Missing deviceName');
    }

    if (!deviceType) {
      throw Error('Missing deviceType');
    }

    const response = await this.http.get('/sil/coefficients', {
      params: { deviceName, deviceType }
    });
    return response.data;
  }
}

const silService = new SilService(Axios);

export { silService };
