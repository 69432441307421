import { CreateRevisionRequest, RevisionHistoryItem, UpdateRevisionRequest } from '@/types';
import Axios, { AxiosStatic } from 'axios';

class RevisionService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async getRevisions(projectId: string): Promise<RevisionHistoryItem[]> {
    const url: string = `/revision/${projectId}`;
    const response = await Axios.get(url);
    return response.data;
  }

  public async createRevision(payload: CreateRevisionRequest): Promise<RevisionHistoryItem[]> {
    const url: string = `/revision/${payload.projectId}`;
    const response: any = await this.http.post(url, payload);
    return response.data;
  }

  public async updateRevision(payload: UpdateRevisionRequest): Promise<RevisionHistoryItem[]> {
    const url: string = `/revision/${payload.projectId}`;
    const response: any = await this.http.put(url, payload);
    return response.data;
  }
}

const revisionService = new RevisionService(Axios);

export { revisionService };
