import {
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  type AccountInfo,
  type RedirectRequest,
  type SilentRequest
} from '@azure/msal-browser';
import { IAuthenticationService } from './IAuthenticationService';
import { Ref, ref } from 'vue';
import { Store } from 'vuex';
import { msalInstance, msalScopes } from './msalConfig';
import { Router } from 'vue-router';

class MsalAuthenticationService implements IAuthenticationService {
  public isSalesforceAuthentication: boolean = false;
  private isAuthenticatedRef: Ref<boolean> = ref(false);
  private _router: Router | undefined = undefined;

  get isAuthenticated(): Ref<boolean> {
    this.isAuthenticatedRef.value = msalInstance.getAllAccounts().length > 0;
    return this.isAuthenticatedRef;
  }

  get isFetchingRefreshToken(): boolean {
    return false;
  }

  get isValmetUser(): boolean {
    return true;
  }

  get nelesContact(): string {
    return this.userName;
  }

  get userName(): string {
    return msalInstance.getAllAccounts()[0]?.name ?? '';
  }

  public setup(store: Store<any>, router: Router): void {
    try {
      (async () => {
        if (msalInstance === undefined) {
          throw new Error('MSAL not initialized. Call initializeMsal() before using MSAL API');
        }

        this._router = router;
        msalInstance.addEventCallback((message: EventMessage) => {
          if (message.eventType === EventType.LOGIN_SUCCESS) {
            const accountInfo: AccountInfo = message.payload as AccountInfo;

            if (!!accountInfo) {
              msalInstance.setActiveAccount(accountInfo);
            }
          }

          if (message.eventType === EventType.HANDLE_REDIRECT_END) {
            const requestedUrl = localStorage.getItem('requestedUrl');

            if (!!requestedUrl) {
              this._router!.push(requestedUrl!);
              localStorage.removeItem('requestedUrl');
            }
          }
        });

        await msalInstance.initialize();
        await msalInstance.handleRedirectPromise();
      })();
    } catch (error) {
      console.error(`Initialize error: ${error}`);
    }
  }

  public async login(): Promise<void> {
    try {
      const request: RedirectRequest = {
        scopes: msalScopes
      };
      await msalInstance.loginRedirect(request);
    } catch (error) {
      console.error(`Login error: ${error}`);
    }
  }

  public async logout(): Promise<void> {
    await msalInstance.logoutRedirect();
  }

  public async getToken(): Promise<String> {
    const request: SilentRequest = {
      scopes: msalScopes
    };

    try {
      const acquireTokenSilentResponse = await msalInstance.acquireTokenSilent(request);
      return acquireTokenSilentResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        try {
          const acquireTokenPopupResponse = await msalInstance.acquireTokenPopup(request);
          return acquireTokenPopupResponse.accessToken;
        } catch (error) {
          console.error(`Get access token failed: ${error}`);
        }
      }
    }

    return '';
  }
}

export default MsalAuthenticationService;
