import { Version } from '@/types';
import Axios, { AxiosStatic } from 'axios';

class VersionService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  public async revertVersion(payload: any): Promise<Version> {
    const url: string = `/version/${payload.projectId}`;
    const response: any = await this.http.post(url, payload);
    return response.data;
  }

  public async updateVersionComment(payload: any): Promise<Version> {
    const url: string = `/version/${payload.projectId}`;
    const response: any = await this.http.put(url, payload);
    return response.data;
  }
}

const versionService = new VersionService(Axios);

export { versionService };
