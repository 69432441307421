import { helpers } from '@vuelidate/validators';

export const npfNumeric = (value: any): boolean => {
  return value === '' || value === undefined ? true : !isNaN(value);
};

export const npfSmallerThan = (target: any) =>
  helpers.withParams({ type: 'npfSmallerThan', eq: target }, function (value: any) {
    return target === '' || target === undefined || value === '' || value === undefined ? true : value < target;
  });

export const npfGreaterThan = (target: any) =>
  helpers.withParams({ type: 'npfGreaterThan', eq: target }, function (value: any) {
    return target === '' || target === undefined || value === '' || value === undefined ? true : value > target;
  });
