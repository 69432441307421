import { cloneDeep, pick } from 'lodash';

export * from './DownloadHelper';
export * from './createProgressIndicatorAction';
export * from './UIComponentSizeHelper';
export * from './FlowCaseColumnHelper';

export function cloneDeepExistingProperties(source: any, destination: any): any {
  return cloneDeep(pick(source, Object.keys(destination)));
}

export function arrayDifference<T>(array1: T[], array2: T[]): T[] {
  return array1.filter((value) => !array2.includes(value));
}

export function toScientificNotation(value: number | undefined, significantDigits: number): string | undefined {
  return value !== undefined ? parseFloat(value.toPrecision(significantDigits)).toExponential() : undefined;
}

export function ceilProps(object: any): any {
  if (object) {
    const ret = { ...object };
    Object.keys(ret).forEach((key) => {
      ret[key] = Math.ceil(ret[key]);
    });
    return ret;
  }
}

export function getRemInPx(): number {
  return parseFloat(window.getComputedStyle(document.documentElement).fontSize);
}

export async function sleep(ms: number): Promise<any> {
  return new Promise((callback) => setTimeout(callback, ms));
}
