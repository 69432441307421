export const flowCaseSubColumnProps = {
  caseIndex: {
    type: Number,
    default: 0
  },
  startRowInParent: {
    type: Number,
    default: 1
  },
  rowCount: {
    type: Number,
    default: 1
  },
  someSiblingFilled: {
    type: Boolean,
    default: false
  }
};
