import Axios, { AxiosStatic } from 'axios';
import { AreaDetails } from '@/types/SizingTree';
import { Area } from '@/types';
import { CrudResponse } from '@/types/response/crudResponse';

class AreaService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async createArea(projectId: string, versionId: string, area: AreaDetails): Promise<CrudResponse> {
    const response = await this.http.post(`/area/${projectId}/${versionId}`, area);
    return response.data;
  }

  async updateArea(projectId: string, versionId: string, area: Area): Promise<CrudResponse> {
    const response = await this.http.put(`/area/${projectId}/${versionId}/${area.areaId}`, area);
    return response.data;
  }

  async deleteArea(projectId: string, versionId: string, areaId: string): Promise<CrudResponse> {
    const response = await this.http.delete(`/area/${projectId}/${versionId}/${areaId}`);
    return response.data;
  }
}

const areaService = new AreaService(Axios);

export { areaService };
