import { BrowserCacheLocation, Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/65f8dbd7-eb30-4ddc-88b3-f1f6fbea6ba2`,
    clientId: 'cd3d8ad8-ea6d-43f1-9570-2b590e749be7',
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage
  }
  // system: {
  //   loggerOptions: {
  //     //logLevel: LogLevel.Verbose,
  //     // loggerCallback: (level, message, containsPii) => {
  //     //   if (containsPii) {
  //     //     return;
  //     //   }

  //     //   switch (level) {
  //     //     case LogLevel.Error:
  //     //       console.error(message);
  //     //       return;
  //     //     case LogLevel.Info:
  //     //       console.info(message);
  //     //       return;
  //     //     case LogLevel.Verbose:
  //     //       console.debug(message);
  //     //       return;
  //     //     case LogLevel.Warning:
  //     //       console.warn(message);
  //     //       return;
  //     //   }
  //     // },
  //     //piiLoggingEnabled: false
  //   }
  // }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const msalScopes = ['49c2b87a-fa8c-4ab4-b115-6d7758ca5953/user_impersonation'];
